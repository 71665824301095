var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { FirebaseAuth } from "../../FirebaseAuth/firebase";
import UserMenu from '../../menus/UserMenu';
import AppMenu from '../../menus/AppMenu';
import PublicTemplate from "../PublicTemplate";
import Loader from "../../Loader";
import { AuthContext } from "../../FirebaseAuth";
import AccountMenu from "../../menus/AccountMenu";
import Logo from '../../Logo';
import { BreadcrumbContext, Breadcrumb } from '../../Breadcrumb';
var AccountTemplate = function (_a) {
    var roles = _a.roles, children = _a.children;
    var accountId = useParams().accountId;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useContext(AuthContext), setUserData = _d.setUserData, authUser = _d.authUser;
    var _e = useState(false), isActive = _e[0], setIsActive = _e[1];
    var _f = useState([]), breadcrumb = _f[0], setBreadcrumb = _f[1];
    useEffect(function () {
        var account = {
            id: '',
            name: '',
            subscriptionStatus: 'active',
            firebase: true,
            owner: '',
            planId: '',
            price: 0,
            currency: '',
            paymentCycle: '',
            subscriptionCurrentPeriodEnd: 0,
            features: {
                trial: true,
                custom_domain: false,
                priority_support: false,
                error_notifications: false,
                multiple_roles: false,
                dedicated_cpu: false,
            },
            role: 'user',
            stripeActiveSubscriptionID: '',
            type: 'provider',
            plans: [],
        };
        setLoading(true);
        var accountRef = FirebaseAuth.firestore().doc('accounts/' + accountId);
        accountRef.get().then(function (doc) {
            var _a, _b, _c;
            var data = doc.data();
            if (doc.exists && data !== undefined) {
                if (data.subscriptionStatus && data.subscriptionStatus === 'active') {
                    setIsActive(true);
                }
                account.id = doc.id;
                account.owner = data.owner;
                account.name = data.name;
                account.planId = undefined;
                if (data.plan) {
                    account.planId = data.plan.id;
                }
                account.price = data.price;
                account.subscriptionStatus = data.subscriptionStatus;
                if (((_a = authUser.user) === null || _a === void 0 ? void 0 : _a.uid) && data.admins.includes(authUser.user.uid)) {
                    account.role = 'admin';
                }
                else if (((_b = authUser.user) === null || _b === void 0 ? void 0 : _b.uid) && data.registrars.includes(authUser.user.uid)) {
                    account.role = 'registrar';
                }
                else if (((_c = authUser.user) === null || _c === void 0 ? void 0 : _c.uid) && data.financiers.includes(authUser.user.uid)) {
                    account.role = 'financier';
                }
                else {
                    account.role = 'user';
                }
                setUserData(function (userData) { return (__assign(__assign({}, userData), { currentAccount: account })); });
                if (!roles.includes(account.role) && !roles.includes('*')) {
                    setError('Permission denied.');
                }
                setLoading(false);
            }
            else {
                setError('Invalid account.');
                setLoading(false);
            }
        }).catch(function (err) {
            setError(err.message);
            setLoading(false);
        });
    }, [accountId, setUserData, roles, authUser]);
    return (_jsx(_Fragment, { children: loading ? (_jsx(PublicTemplate, { children: _jsx(Loader, { size: "5x", text: "Loading..." }) })) : (_jsx(_Fragment, { children: error === null ? (_jsxs("div", { className: "c-app", children: [_jsxs("div", { className: "c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show", id: "sidebar", children: [_jsx("div", { className: "c-sidebar-brand d-md-down-none", children: _jsx(Logo, {}) }), isActive ? (_jsx(AccountMenu, {})) : (_jsx(AppMenu, {})), _jsx("button", { className: "c-sidebar-minimizer c-class-toggler", "data-target": "_parent", "data-class": "c-sidebar-minimized", type: "button" })] }), _jsxs("div", { className: "c-wrapper", children: [_jsxs("header", { className: "c-header c-header-light c-header-fixed", children: [_jsx("button", { className: "c-header-toggler c-class-toggler d-lg-none mfe-auto", type: "button", "data-target": "#sidebar", "data-class": "c-sidebar-show", children: _jsx("i", { className: "mt-3 fa fa-bars" }) }), _jsx("div", { children: _jsx("button", { className: "c-header-toggler c-class-toggler mfs-3 d-md-down-none", "data-target": "#sidebar", "data-class": "c-sidebar-lg-show", children: _jsx("i", { className: "mt-3 fa fa-bars" }) }) }), _jsx(Breadcrumb, { links: breadcrumb }), _jsx("ul", { className: "c-header-nav", children: _jsx(UserMenu, {}) })] }), _jsx("div", { className: "c-body", children: _jsx("main", { className: "c-main", children: _jsx(BreadcrumbContext.Provider, { value: { setBreadcrumb: setBreadcrumb }, children: children }) }) })] })] })) : (_jsx(PublicTemplate, { children: error })) })) }));
};
export default AccountTemplate;
