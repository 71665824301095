import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//a react component that allow the user to copy a value to their clipboard
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
var CopyableElement = function (_a) {
    var value = _a.value;
    var _b = useState(false), isCopied = _b[0], setIsCopied = _b[1];
    var onCopyText = function () {
        setIsCopied(true);
        setTimeout(function () {
            setIsCopied(false);
        }, 2000);
    };
    return (_jsxs(_Fragment, { children: [_jsx("code", { children: value }), _jsx(CopyToClipboard, { text: value, onCopy: onCopyText, children: _jsxs("div", { className: "copy-area", children: [_jsx("button", { className: "btn btn-info mr-1", children: _jsx("i", { className: "far fa-copy" }) }), isCopied ? (_jsx("span", { style: { color: "var(--danger)" }, children: "Copied." })) : null] }) })] }));
};
export default CopyableElement;
