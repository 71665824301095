var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
var Select = function (props) {
    var hasError = props.hasError, validRegex = props.validRegex, customErrorMessage = props.customErrorMessage, minLen = props.minLen, maxLen = props.maxLen, required = props.required, error = props.error, changeHandler = props.changeHandler, options = props.options, others = __rest(props, ["hasError", "validRegex", "customErrorMessage", "minLen", "maxLen", "required", "error", "changeHandler", "options"]);
    var _a = useState(hasError), hasErrorState = _a[0], setHasErrorState = _a[1];
    var _b = useState(error), errorMessage = _b[0], setErrorMessage = _b[1];
    useEffect(function () {
        setHasErrorState(hasError);
        setErrorMessage(error);
    }, [hasError, error]);
    return (_jsxs(_Fragment, { children: [_jsx("select", __assign({ className: "form-control" + (hasErrorState ? ' is-invalid' : '') }, others, { onChange: function (e) {
                    var foundError = false;
                    var foundErrorMessage = '';
                    // validate required
                    if (typeof (required) !== 'undefined' && required) {
                        if (e.target.value.trim().length === 0) {
                            foundErrorMessage = 'This is a required field.';
                            foundError = true;
                        }
                    }
                    // validate length
                    if (!foundError && typeof (minLen) !== 'undefined' && minLen !== 0) {
                        if (e.target.value.length < minLen) {
                            foundErrorMessage = 'The input must be at least ' + minLen + ' characters.';
                            foundError = true;
                        }
                    }
                    if (!foundError && typeof (maxLen) !== 'undefined' && maxLen !== 0) {
                        if (e.target.value.length > maxLen) {
                            foundErrorMessage = 'The input must be no more than ' + maxLen + ' characters.';
                            foundError = true;
                        }
                    }
                    // validate the value against validation regex
                    if (!foundError && typeof (validRegex) !== 'undefined' && validRegex !== '') {
                        if (!RegExp(validRegex).test(e.target.value) && ((required && e.target.value !== '') || required)) {
                            if (typeof (customErrorMessage) !== 'undefined') {
                                foundErrorMessage = customErrorMessage;
                            }
                            else {
                                foundErrorMessage = 'The input format is invalid.';
                            }
                            foundError = true;
                        }
                    }
                    if (foundError) {
                        setHasErrorState(true);
                        setErrorMessage(foundErrorMessage);
                    }
                    else {
                        setHasErrorState(false);
                    }
                    changeHandler({
                        hasError: foundError,
                        error: foundErrorMessage,
                        value: e.target.value
                    });
                }, children: options.map(function (_a, index) {
                    var value = _a.value, label = _a.label, disabled = _a.disabled;
                    return _jsx("option", { value: value, disabled: disabled, children: label }, value);
                }) })), hasErrorState &&
                _jsx("div", { className: "invalid-feedback", children: errorMessage })] }));
};
Select.propTypes = {
    name: PropTypes.string,
    validRegex: PropTypes.string,
    customErrorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    error: PropTypes.string,
    minLen: PropTypes.number,
    maxLen: PropTypes.number,
    required: PropTypes.bool,
    changeHandler: PropTypes.func,
    options: PropTypes.array
};
export default Select;
