var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Form, Field, Input, Select } from "../../../../components/Form";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import Highlight from "react-highlight";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "react-bootstrap";
var EditRegistration = function (_a) {
    var _b;
    var data = _a.data;
    var title = (data === null || data === void 0 ? void 0 : data.id) ? "Editing Registration: ".concat(data.id) : "New Registration";
    var platformId = (data === null || data === void 0 ? void 0 : data.id) ? data.id : null;
    var _c = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.url) || ""
    }), url = _c[0], setUrl = _c[1];
    var _d = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.clientId) || ""
    }), clientId = _d[0], setClientId = _d[1];
    var _e = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.name) || ""
    }), name = _e[0], setName = _e[1];
    var _f = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.authenticationEndpoint) || ""
    }), authenticationEndpoint = _f[0], setAuthenticationEndpoint = _f[1];
    var _g = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.accesstokenEndpoint) || ""
    }), accesstokenEndpoint = _g[0], setAccesstokenEndpoint = _g[1];
    var _h = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.authorizationServer) || ""
    }), authorizationServer = _h[0], setAuthorizationServer = _h[1];
    var _j = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.authConfig) ? data.authConfig.method : "JWK_SET"
    }), authConfigMethod = _j[0], setAuthConfigMethod = _j[1];
    var _k = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.authConfig) ? data.authConfig.key : ""
    }), authConfigKey = _k[0], setAuthConfigKey = _k[1];
    var _l = useState({
        hasError: false,
        error: null,
        value: (_b = data === null || data === void 0 ? void 0 : data.lmsType) !== null && _b !== void 0 ? _b : "other"
    }), lmsType = _l[0], setLmsType = _l[1];
    var _m = useState({
        hasError: false,
        error: null,
        value: (data === null || data === void 0 ? void 0 : data.url) === "https://canvas.instructure.com" ? "canvas_cloud" : "canvas_self_hosted"
    }), canvasUrlType = _m[0], setCanvasUrlType = _m[1];
    var _o = useState(null), registrationInfo = _o[0], setRegistrationInfo = _o[1];
    var _p = useState({
        id: data === null || data === void 0 ? void 0 : data.id,
        url: url.value,
        clientId: clientId.value,
        name: name.value,
        authenticationEndpoint: authenticationEndpoint.value,
        accesstokenEndpoint: accesstokenEndpoint.value,
        authorizationServer: authorizationServer.value,
        authConfig: {
            method: authConfigMethod.value,
            key: authConfigKey.value
        },
        lmsType: lmsType.value
    }), registration = _p[0], setRegistration = _p[1];
    var _q = useState(false), inSubmit = _q[0], setInSubmit = _q[1];
    var _r = useState(null), errorMessage = _r[0], setErrorMessage = _r[1];
    var _s = useState(null), successMessage = _s[0], setSuccessMessage = _s[1];
    var _t = useState(null), APIResponse = _t[0], setAPIResponse = _t[1];
    var userData = useContext(AuthContext).userData;
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    useEffect(function () {
        var reg = {
            //lmsType: lmsType.value,
            url: url.value,
            clientId: clientId.value,
            name: name.value,
            authenticationEndpoint: authenticationEndpoint.value,
            accesstokenEndpoint: accesstokenEndpoint.value,
            authorizationServer: authorizationServer.value === "" ? accesstokenEndpoint.value : authorizationServer.value,
            authConfig: {
                method: authConfigMethod.value,
                key: authConfigKey.value
            }
        };
        if (lmsType.value === "blackboard") {
            reg.url = "https://blackboard.com";
            reg.accesstokenEndpoint = "https://developer.blackboard.com/api/v1/gateway/oauth2/jwttoken";
            reg.authorizationServer = "https://developer.blackboard.com/api/v1/gateway/oauth2/jwttoken";
            reg.authenticationEndpoint = "https://developer.blackboard.com/api/v1/gateway/oidcauth";
            reg.authConfig.method = "JWK_SET";
        }
        else if (lmsType.value === "brightspace") {
            reg.authConfig.key = url.value + "/d2l/.well-known/jwks";
            reg.accesstokenEndpoint = "https://auth.brightspace.com/core/connect/token";
            reg.authenticationEndpoint = url.value + "/d2l/lti/authenticate";
            reg.authConfig.method = "JWK_SET";
            reg.authorizationServer = "https://api.brightspace.com/auth/token";
        }
        else if (lmsType.value === "canvas_cloud") {
            reg.url = "https://canvas.instructure.com";
            reg.authConfig.key = "https://sso.canvaslms.com/api/lti/security/jwks";
            reg.accesstokenEndpoint = "https://sso.canvaslms.com/login/oauth2/token";
            reg.authorizationServer = "https://sso.canvaslms.com/login/oauth2/token";
            reg.authenticationEndpoint = "https://sso.canvaslms.com/api/lti/authorize_redirect";
            reg.authConfig.method = "JWK_SET";
        }
        else if (lmsType.value === "canvas_cloud_test") {
            reg.url = "https://canvas.test.instructure.com";
            reg.authConfig.key = "https://canvas.test.instructure.com/api/lti/security/jwks";
            reg.accesstokenEndpoint = "https://canvas.test.instructure.com/login/oauth2/token";
            reg.authorizationServer = "https://canvas.test.instructure.com/login/oauth2/token";
            reg.authenticationEndpoint = "https://canvas.test.instructure.com/api/lti/authorize_redirect";
            reg.authConfig.method = "JWK_SET";
        }
        else if (lmsType.value === "canvas_self_hosted") {
            reg.url = canvasUrlType.value === "canvas_cloud" ? "https://canvas.instructure.com" : url.value;
            reg.authConfig.key = url.value + "/api/lti/security/jwks";
            reg.accesstokenEndpoint = url.value + "/login/oauth2/token";
            reg.authorizationServer = url.value + "/login/oauth2/token";
            reg.authenticationEndpoint = url.value + "/api/lti/authorize_redirect";
            reg.authConfig.method = "JWK_SET";
        }
        else if (lmsType.value === "moodle") {
            reg.authConfig.key = url.value + "/mod/lti/certs.php";
            reg.accesstokenEndpoint = url.value + "/mod/lti/token.php";
            reg.authorizationServer = url.value + "/mod/lti/token.php";
            reg.authenticationEndpoint = url.value + "/mod/lti/auth.php";
            reg.authConfig.method = "JWK_SET";
        }
        else if (lmsType.value === "schoology") {
            reg.url = "https://schoology.schoology.com";
            reg.authenticationEndpoint = "https://lti-service.svc.schoology.com/lti-service/authorize-redirect";
            reg.accesstokenEndpoint = "https://lti-service.svc.schoology.com/lti-service/access-token";
            reg.authorizationServer = "https://lti-service.svc.schoology.com/lti-service/access-token";
            reg.authConfig.key = "https://lti-service.svc.schoology.com/lti-service/.well-known/jwks";
            reg.authConfig.method = "JWK_SET";
        }
        else if (lmsType.value === "other") {
            //TODO: fill in URLS for LMSes
        }
        setRegistration(reg);
        setRegistrationInfo(JSON.stringify(reg, null, 2));
    }, [url, clientId, name, authenticationEndpoint, accesstokenEndpoint, authorizationServer, authConfigMethod, authConfigKey, lmsType, canvasUrlType]);
    function validateForm() {
        return ((url.value === "" && registration.url === "") ||
            clientId.value === "" ||
            name.value === "" ||
            (authenticationEndpoint.value === "" && registration.authenticationEndpoint === "") ||
            (accesstokenEndpoint.value === "" && registration.accesstokenEndpoint === "") ||
            (authConfigKey.value === "" && registration.authConfig.key === "") ||
            inSubmit);
    }
    var sendRegistration = function () { return __awaiter(void 0, void 0, void 0, function () {
        var editPlatformRegistration, registerPlatform;
        return __generator(this, function (_a) {
            setInSubmit(true);
            if (platformId) {
                editPlatformRegistration = CloudFunctions.httpsCallable('oncall');
                editPlatformRegistration({
                    name: "editPlatformRegistration",
                    data: {
                        accountId: userData.currentAccount.id,
                        platformId: platformId,
                        data: registration
                    }
                }).then(function (res) {
                    setInSubmit(false);
                    if (res.data.hasOwnProperty('error')) {
                        setErrorMessage("Platform registeration edit failed: ".concat(res.data.details.message));
                        setAPIResponse(JSON.stringify(res.data, null, 2));
                    }
                    else {
                        setSuccessMessage("Platform registeration edited successfully.");
                        setAPIResponse(JSON.stringify(res.data, null, 2));
                    }
                }).catch(function (err) {
                    setErrorMessage(err.message);
                    setInSubmit(false);
                });
            }
            else {
                registerPlatform = CloudFunctions.httpsCallable('oncall');
                registerPlatform({
                    name: "registerPlatform",
                    data: {
                        accountId: userData.currentAccount.id,
                        data: registration
                    }
                }).then(function (res) {
                    setInSubmit(false);
                    if (res.data.hasOwnProperty('error')) {
                        setErrorMessage("Platform registeration failed: ".concat(res.data.details.message));
                        setAPIResponse(JSON.stringify(res.data, null, 2));
                    }
                    else {
                        setSuccessMessage("Platform registered successfully.");
                        setAPIResponse(JSON.stringify(res.data, null, 2));
                    }
                }).catch(function (err) {
                    setErrorMessage(err.message);
                    setInSubmit(false);
                });
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false,
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false,
            },
            {
                to: null,
                text: title,
                active: true,
            },
        ]);
    }, [setBreadcrumb, title]);
    var validUrlRegex = "https?:\\/\\/((?!(localhost|(127\\.)|(192\\.168\\.)|(10\\.)|(172\\.1[6-9]\\.)|(172\\.2[0-9]\\.)|(172\\.3[0-1]\\.)|(::1$)|([fF][cCdD]))).)[a-zA-Z0-9\\.\\-\\_]+.+";
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsxs("div", { className: "animated fadeIn", children: [errorMessage !== null && (_jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })), successMessage !== null && (_jsx(Alert, { type: "success", message: successMessage, dismissible: true, onDismiss: function () { return setSuccessMessage(null); } })), APIResponse !== null && (_jsx("div", { children: _jsx(Highlight, { className: 'language-json', children: APIResponse }) })), APIResponse === null && (_jsx("div", { className: "card", children: _jsxs("div", { className: "card-body", children: [_jsxs(Form, { handleSubmit: function (e) {
                                        e.preventDefault();
                                        setInSubmit(true);
                                        setErrorMessage(null);
                                        sendRegistration().catch(function (err) {
                                            setErrorMessage(err.message);
                                            setInSubmit(false);
                                        });
                                    }, disabled: validateForm(), inSubmit: inSubmit, enableDefaultButtons: true, children: [_jsx(Field, { label: "LMS", description: "Which LMS manufacturer does this LMS belong to?", children: _jsx(Select, { value: lmsType.value, name: "lmsType", required: true, 
                                                // @ts-ignore
                                                changeHandler: setLmsType, options: [
                                                    { label: "Blackboard", value: "blackboard" },
                                                    { label: "Brightspace (D2L)", value: "brightspace" },
                                                    { label: "Canvas (Instructure Cloud)", value: "canvas_cloud" },
                                                    { label: "Canvas (Instructure Test Cloud)", value: "canvas_cloud_test" },
                                                    { label: "Canvas (Self Hosted)", value: "canvas_self_hosted" },
                                                    { label: "Moodle", value: "moodle" },
                                                    { label: "Schoology", value: "schoology" },
                                                    { label: "Other", value: "other" }
                                                ] }) }), _jsx("hr", {}), _jsx(Field, { label: "Name", description: "Platform name", children: _jsx(Input, { type: "text", value: name.value, name: "name", required: true, changeHandler: setName }) }), lmsType.value !== "canvas_cloud" && lmsType.value !== "canvas_cloud_test" && lmsType.value !== "blackboard" && lmsType.value !== "schoology" &&
                                            _jsx(Field, { label: "URL", description: "Platform URL (also known as 'Issuer')", children: _jsx(Input, { type: "text", value: url.value, name: "url", required: true, validRegex: validUrlRegex, customErrorMessage: "All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet.", changeHandler: setUrl }) }), lmsType.value === "canvas_self_hosted" &&
                                            _jsx(Field, { label: "Use Canvas Global URL", description: "If the self-hosted Canvas server is not configured correctly (which happens often), the URL that the server is expecting to match is actually https://canvas.instructure.com instead of ".concat(url.value.length > 0 ? url.value : "https://[SERVER_URL]", ". Which one would you liek to set in this registration?"), children: _jsxs("div", { className: "toggle", children: [_jsx(Input, { type: "radio", name: "canvas_self_hosted_url", value: "canvas_cloud", required: true, changeHandler: setCanvasUrlType, id: "autoActivateTrue", checked: canvasUrlType.value !== "canvas_self_hosted" }), _jsx("label", { htmlFor: "autoActivateTrue", children: "https://canvas.instructure.com" }), _jsx(Input, { type: "radio", name: "canvas_self_hosted_url", value: "canvas_self_hosted", required: true, changeHandler: setCanvasUrlType, id: "autoActivateFalse", checked: canvasUrlType.value !== "canvas_cloud" }), _jsx("label", { htmlFor: "autoActivateFalse", children: url.value.length > 0 ? url.value : "Server URL" })] }) }), _jsx(Field, { label: lmsType.value === "blackboard" ? "Application ID" : "Client ID", description: "Platform client ID", children: _jsx(Input, { type: "text", value: clientId.value, name: "clientId", required: true, changeHandler: setClientId }) }), lmsType.value === "other" &&
                                            _jsxs(_Fragment, { children: [_jsx(Field, { label: "Authentication Endpoint", description: "Platform authentication endpoint", children: _jsx(Input, { type: "text", value: authenticationEndpoint.value, name: "authenticationEndpoint", required: true, validRegex: validUrlRegex, customErrorMessage: "All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet.", changeHandler: setAuthenticationEndpoint }) }), _jsx(Field, { label: "Access Token Endpoint", description: "Platform access token endpoint", children: _jsx(Input, { type: "text", value: accesstokenEndpoint.value, name: "Access Token Endpoint", required: true, validRegex: validUrlRegex, customErrorMessage: "All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet.", changeHandler: setAccesstokenEndpoint }) }), _jsx(Field, { label: "Authorization Server", description: "For all LMSes except D2L, this value can be left blank and LTIAAS will set it to match the 'Accesstoken Endpoint'. ", children: _jsx(Input, { type: "text", value: authorizationServer.value, name: "Authorization Server", required: false, validRegex: validUrlRegex, customErrorMessage: "All URLs must be HTTPS and accessible to our servers on the internet. Localhost is not allowed. Use a dynamic DNS service like NGROK to exposure your server to the internet.", changeHandler: setAuthorizationServer }) }), _jsx(Field, { label: "Authentication Method", description: "Platform token authentication method", children: _jsx(Select, { value: authConfigMethod.value, name: "AuthConfig Method", required: true, options: [
                                                                { value: "JWK_SET", label: "JWK_SET" },
                                                                { value: "JWK_KEY", label: "JWK_KEY" },
                                                                { value: "RSA_KEY", label: "RSA_KEY" }
                                                            ], 
                                                            // @ts-ignore
                                                            changeHandler: setAuthConfigMethod }) })] }), (lmsType.value === "other" || lmsType.value === "blackboard") &&
                                            _jsx(_Fragment, { children: _jsx(Field, { label: lmsType.value === "blackboard" ? "Public keyset URL" : "Authentication Key", description: "Platform token authentication key", children: _jsx(Input, { type: "text", value: authConfigKey.value, name: "AuthConfig Key", required: true, changeHandler: setAuthConfigKey }) }) }), errorMessage !== null && (_jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } }))] }), lmsType.value !== "other" && (_jsxs(Card, { children: [_jsx(CardHeader, { children: "Calculated Registration Values" }), _jsxs(CardBody, { children: [_jsx(Alert, { type: "info", message: "These values are calculated based on the values you entered above. You can change these values at any time after initial registration." }), _jsx(Highlight, { className: 'language-json', children: registrationInfo })] })] }))] }) }))] }) }) }));
};
EditRegistration.propTypes = {
    data: PropTypes.object
};
export default EditRegistration;
