var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
var animatedComponents = makeAnimated();
var MultiSelect = function (props) {
    var hasError = props.hasError, minLen = props.minLen, maxLen = props.maxLen, required = props.required, error = props.error, changeHandler = props.changeHandler, options = props.options, disabled = props.disabled, others = __rest(props, ["hasError", "minLen", "maxLen", "required", "error", "changeHandler", "options", "disabled"]);
    var _a = useState(hasError), hasErrorState = _a[0], setHasErrorState = _a[1];
    var _b = useState(error), errorMessage = _b[0], setErrorMessage = _b[1];
    useEffect(function () {
        setHasErrorState(hasError);
        setErrorMessage(error);
    }, [hasError, error]);
    return (_jsxs(_Fragment, { children: [_jsx(Select, __assign({ className: "form-multi-select form-control" + (hasErrorState ? ' is-invalid' : ''), options: options, isSearchable: true, isMulti: true, components: animatedComponents, isDisabled: disabled }, others, { onChange: function (e, action) {
                    var foundError = false;
                    var foundErrorMessage = '';
                    // validate required
                    if (typeof (required) !== 'undefined' && required) {
                        if (e.length === 0) {
                            foundErrorMessage = 'You need to select at least one option.';
                            foundError = true;
                        }
                    }
                    // validate length
                    if (!foundError && typeof (minLen) !== 'undefined' && minLen !== 0) {
                        if (e.length < minLen) {
                            foundErrorMessage = 'You must select at least ' + minLen + ' items.';
                            foundError = true;
                        }
                    }
                    if (!foundError && typeof (maxLen) !== 'undefined' && maxLen !== 0) {
                        if (e.length > maxLen) {
                            foundErrorMessage = 'You can only select at most ' + maxLen + ' items.';
                            foundError = true;
                        }
                    }
                    if (foundError) {
                        setHasErrorState(true);
                        setErrorMessage(foundErrorMessage);
                    }
                    else {
                        setHasErrorState(false);
                    }
                    var values = e.map(function (item) { return item.value; });
                    changeHandler({
                        hasError: foundError,
                        error: foundErrorMessage,
                        value: values
                    });
                } })), hasErrorState &&
                _jsx("div", { className: "invalid-feedback", children: errorMessage })] }));
};
MultiSelect.propTypes = {
    name: PropTypes.string,
    customErrorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    error: PropTypes.string,
    minLen: PropTypes.number,
    maxLen: PropTypes.number,
    required: PropTypes.bool,
    changeHandler: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.array
};
export default MultiSelect;
