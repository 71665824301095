import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect, createRef } from "react";
import { Link } from 'react-router-dom';
import { Form, Field, Input } from '../../../../components/Form';
import firebase from "firebase/compat/app";
import { AuthContext } from '../../../../components/FirebaseAuth';
import Alert from '../../../../components/Alert';
import UserPageLayout from '../../../../components/user/UserPageLayout';
import { log, UPDATE_PHONE } from "../../../../libs/log";
var UpdatePhone = function () {
    var title = "Change Your Phone Number";
    var _a = useState({
        hasError: false,
        error: null,
        value: ""
    }), phoneNumber = _a[0], setPhoneNumber = _a[1];
    var _b = useState({
        hasError: false,
        error: null,
        value: ""
    }), verificationCode = _b[0], setVerificationCode = _b[1];
    var authUser = useContext(AuthContext).authUser;
    var SUCCESS = 'success';
    var FAILURE = 'failure';
    var PHONESTEP = 'phone';
    var VERIFYSTEP = 'verify';
    var _c = useState({
        status: PHONESTEP,
        message: ''
    }), result = _c[0], setResult = _c[1];
    var _d = useState(false), inSubmit = _d[0], setInSubmit = _d[1];
    var recaptcha = createRef();
    var _e = useState(false), recaptchaVerified = _e[0], setRecaptchaVerified = _e[1];
    var _f = useState(''), verificationId = _f[0], setVerificationId = _f[1];
    useEffect(function () {
        if (result.status === PHONESTEP) {
            //@ts-ignore
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptcha, {
                'size': 'normal',
                'callback': function (response) {
                    setRecaptchaVerified(true);
                },
                'expired-callback': function () {
                    setRecaptchaVerified(false);
                }
            });
            //@ts-ignore
            window.recaptchaVerifier.render().then(function (widgetId) {
                //@ts-ignore
                window.recaptchaWidgetId = widgetId;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);
    return (_jsxs(UserPageLayout, { title: title, children: [result.status === PHONESTEP &&
                _jsxs(Form, { handleSubmit: function (e) {
                        e.preventDefault();
                        setInSubmit(true);
                        setRecaptchaVerified(false);
                        var provider = new firebase.auth.PhoneAuthProvider();
                        provider.verifyPhoneNumber(phoneNumber.value, 
                        //@ts-ignore
                        window.recaptchaVerifier).then(function (vid) {
                            setRecaptchaVerified(true);
                            setVerificationId(vid);
                            setResult({
                                status: VERIFYSTEP,
                                message: ''
                            });
                            setInSubmit(false);
                        }).catch(function (err) {
                            setPhoneNumber({
                                hasError: true,
                                error: err.message,
                                value: phoneNumber.value
                            });
                            setInSubmit(false);
                        });
                    }, disabled: phoneNumber.hasError || phoneNumber.value === "" || !recaptchaVerified || inSubmit, inSubmit: inSubmit, enableDefaultButtons: true, backToUrl: "/user/profile", children: [_jsx(Field, { label: "Phone Number", children: _jsx(Input, { type: "text", name: "phone-number", hasError: phoneNumber.hasError, error: phoneNumber.error, required: true, changeHandler: setPhoneNumber }) }), _jsx(Field, { label: "", children: _jsx("div", { ref: function (ref) { return recaptcha = ref; } }) })] }), result.status === VERIFYSTEP &&
                _jsx(Form, { handleSubmit: function (e) {
                        var _a;
                        e.preventDefault();
                        setInSubmit(true);
                        var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode.value);
                        (_a = authUser.user) === null || _a === void 0 ? void 0 : _a.updatePhoneNumber(cred).then(function () {
                            log(UPDATE_PHONE);
                            setResult({
                                status: SUCCESS,
                                message: 'Your phone number has been updated.'
                            });
                            setInSubmit(false);
                        }).catch(function (err) {
                            setResult({
                                status: FAILURE,
                                message: err.message
                            });
                            setInSubmit(false);
                        });
                    }, disabled: verificationCode.hasError || verificationCode.value === "" || !recaptchaVerified || inSubmit, inSubmit: inSubmit, enableDefaultButtons: true, backToUrl: "/user/profile", children: _jsx(Field, { label: "Verification Code", children: _jsx(Input, { type: "text", name: "verification-code", hasError: verificationCode.hasError, error: verificationCode.error, required: true, changeHandler: setVerificationCode }) }) }), result.status === FAILURE &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "danger", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx("button", { className: "btn btn-primary mr-2", onClick: function () {
                                setResult({
                                    status: PHONESTEP,
                                    message: ''
                                });
                            }, children: "Try Again" }), _jsx(Link, { className: "btn btn-secondary", to: "/user/profile", children: "View Profile" })] }), result.status === SUCCESS &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "success", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx(Link, { className: "btn btn-primary", to: "/user/profile", children: "View Profile" })] })] }));
};
export default UpdatePhone;
