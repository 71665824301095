import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { AuthContext } from '../../FirebaseAuth';
import { userSignOut } from '../../../libs/user';
import { CloudFunctions } from "../../FirebaseAuth/firebase";
import UserAvatar from '../../UserAvatar';
import UserFeedback from "@ntheanh201/react-user-feedback";
var UserMenu = function () {
    var authUser = React.useContext(AuthContext).authUser;
    return (_jsxs(_Fragment, { children: [_jsx(UserFeedback, { disabled: false, allowImage: false, feedbackTypes: [
                    { value: "general", label: "General" },
                    { value: "bug", label: "Bug" },
                    { value: "idea", label: "Idea" },
                    { value: "help", label: "Help" },
                ], onSubmit: function (values, onError) {
                    var _a, _b, _c;
                    //console.log("values: ", values);
                    var sendFeedbackEmail = CloudFunctions.httpsCallable('oncall');
                    sendFeedbackEmail({
                        name: "sendFeedbackEmail",
                        data: {
                            senderEmail: ((_a = authUser.user) === null || _a === void 0 ? void 0 : _a.email) || 'no-email',
                            senderName: ((_b = authUser.user) === null || _b === void 0 ? void 0 : _b.displayName) || 'no-name',
                            title: "".concat(values.type, " message generated by ").concat((_c = authUser.user) === null || _c === void 0 ? void 0 : _c.displayName),
                            message: "Feedback from page: ".concat(window.location.href, ". <br><br>").concat(values.message),
                        }
                    }).catch(function (err) {
                        onError(err.message || 'Something went wrong');
                    });
                } }), _jsx(AuthContext.Consumer, { children: function (context) {
                    var _a, _b;
                    return (_jsxs("li", { className: "c-header-nav-item dropdown", id: "user-menu", children: [_jsx(Link, { to: "/", "data-target": "#root", className: "c-header-nav-link", "data-toggle": "dropdown", "aria-haspopup": "true", "aria-expanded": "false", children: _jsx(UserAvatar, { name: ((_a = context.authUser.user) === null || _a === void 0 ? void 0 : _a.displayName) || "", photoUrl: ((_b = context.authUser.user) === null || _b === void 0 ? void 0 : _b.photoURL) || "", className: "c-avatar-img" }) }), _jsxs("div", { className: "dropdown-menu dropdown-menu-right", style: { minWidth: '182px' }, children: [_jsxs(Link, { className: "dropdown-item", to: "/user/profile", children: [_jsx("i", { className: "fas fa-user fa-sm fa-fw mr-2 text-gray-400" }), "Profile"] }), _jsxs(Link, { className: "dropdown-item", to: "/user/log", children: [_jsx("i", { className: "fas fa-list fa-sm fa-fw mr-2 text-gray-400" }), "Activity Logs"] }), _jsx("div", { className: "dropdown-divider" }), _jsxs(Link, { to: "/", "data-target": "#root", className: "dropdown-item", onClick: function (e) {
                                            e.preventDefault();
                                            userSignOut();
                                        }, children: [_jsx("i", { className: "fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" }), "Sign Out"] })] })] }));
                } })] }));
};
export default UserMenu;
