var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "../Alert";
import { CloudFunctions, FirebaseAuth } from "../FirebaseAuth/firebase";
import { AuthContext } from "../FirebaseAuth";
// a React Component called CustomDomain
var CustomDomain = function (props) {
    var enabled = props.enabled, subdomainName = props.subdomainName, settingsCommited = props.settingsCommited, account = props.account;
    var _a = React.useState(null), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = React.useState(false), dnsCorrect = _b[0], setDnsCorrect = _b[1];
    var _c = React.useState(true), checkingDNS = _c[0], setCheckingDNS = _c[1];
    var _d = React.useState(null), customDomainValidation = _d[0], setCustomDomainValidation = _d[1];
    var _e = React.useState(true), checkingDNSStatus = _e[0], setCheckingDNSStatus = _e[1];
    var _f = React.useState(null), distributionDomainName = _f[0], setDistributionDomainName = _f[1];
    var _g = React.useState(false), isDNSNameCopied = _g[0], setIsDNSNameCopied = _g[1];
    var _h = React.useState(false), isDNSValueCopied = _h[0], setIsDNSValueCopied = _h[1];
    var userData = React.useContext(AuthContext).userData;
    var onCopyDNSNameText = function () {
        setIsDNSNameCopied(true);
        setTimeout(function () {
            setIsDNSNameCopied(false);
        }, 2000);
    };
    var onCopyDNSValueText = function () {
        setIsDNSValueCopied(true);
        setTimeout(function () {
            setIsDNSValueCopied(false);
        }, 2000);
    };
    // Check if DNS is valid for subdomain every time it changes
    React.useEffect(function () {
        function checkDNS() {
            return __awaiter(this, void 0, void 0, function () {
                var response, json, expected_answer;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!enabled) return [3 /*break*/, 3];
                            return [4 /*yield*/, fetch("https://dns.google/resolve?name=".concat(subdomainName))];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            json = _a.sent();
                            expected_answer = "143.198.245.249";
                            if (distributionDomainName !== null) {
                                expected_answer = distributionDomainName + ".";
                            }
                            if (json.hasOwnProperty("Answer") && json.Answer[0].data === expected_answer) {
                                setDnsCorrect(true);
                            }
                            else {
                                setDnsCorrect(false);
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            setDnsCorrect(true);
                            _a.label = 4;
                        case 4:
                            setCheckingDNS(false);
                            return [2 /*return*/];
                    }
                });
            });
        }
        checkDNS();
    }, [subdomainName, checkingDNS, distributionDomainName, enabled]);
    var checkDNS = function () {
        setCheckingDNS(true);
    };
    React.useEffect(function () {
        if (enabled === true) {
            getCustomDomainStatus();
        }
    }, [settingsCommited, enabled]);
    var getCustomDomainStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var manageCustomDomain;
        return __generator(this, function (_a) {
            setCheckingDNSStatus(true);
            setCustomDomainValidation(null);
            setDistributionDomainName(null);
            manageCustomDomain = CloudFunctions.httpsCallable('oncall');
            manageCustomDomain({
                name: "manageCustomDomain",
                data: {
                    accountId: userData.currentAccount.id
                }
            }).then(function (res) {
                if (res.data.state === "cert-validate" && res.data.data.Certificate.DomainValidationOptions[0].ResourceRecord !== null) {
                    setCustomDomainValidation(res.data.data.Certificate.DomainValidationOptions[0]);
                }
                else if (res.data.state === "domain-created") {
                    setCustomDomainValidation(res.data.data.Certificate.DomainValidationOptions[0]);
                    var collectionRef = FirebaseAuth.firestore()
                        .collection("accounts")
                        .doc(userData.currentAccount.id);
                    collectionRef.get().then(function (account_doc) {
                        if (account_doc.exists) {
                            var account_1 = account_doc.data();
                            if (account_1 !== undefined && account_1.hasOwnProperty("distributionDomainName")) {
                                setDistributionDomainName(account_1.distributionDomainName);
                            }
                        }
                    });
                }
                checkDNS();
                setCheckingDNSStatus(false);
            }).catch(function (err) {
                if (!err.message.includes("The specified SSL certificate doesn't exist")) {
                    setErrorMessage(err.message);
                }
                setCheckingDNSStatus(false);
            });
            return [2 /*return*/];
        });
    }); };
    React.useEffect(function () {
        if (account && account.hasOwnProperty("distributionDomainName")) {
            setDistributionDomainName(account.distributionDomainName);
        }
    }, [account]);
    return (_jsxs(_Fragment, { children: [errorMessage !== null && (_jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })), enabled && !dnsCorrect &&
                _jsxs("div", { className: "card border-danger", children: [_jsx("div", { className: "card-header text-danger", children: "Custom Domain Setup" }), _jsxs("div", { className: "card-body", children: [customDomainValidation === null &&
                                    _jsx("p", { children: checkingDNSStatus ? (_jsx("i", { children: "Loading..." })) : _jsxs(_Fragment, { children: [_jsx("i", { children: "Initializing custom domain. This may take 5 minutes..." }), _jsx("br", {}), _jsx("br", {}), _jsx(Button, { onClick: getCustomDomainStatus, disabled: checkingDNSStatus, children: "Check Status" })] }) }), customDomainValidation !== null && customDomainValidation.ValidationStatus === "PENDING_VALIDATION" &&
                                    _jsx("p", { children: checkingDNSStatus ? (_jsx("i", { children: "Loading..." })) :
                                            (_jsxs(_Fragment, { children: ["We need you to validate your ownership of ", _jsx("i", { children: subdomainName }), ".", " ", "To do this, please add the following ", _jsx("b", { children: customDomainValidation.ResourceRecord.Type }), " ", "DNS record to the configuration of the parent domain of ", subdomainName, ".", _jsx("br", {}), _jsx("br", {}), _jsx("b", { children: "Name:" }), " ", _jsx("code", { children: customDomainValidation.ResourceRecord.Name }), _jsx(CopyToClipboard, { text: customDomainValidation.ResourceRecord.Name, onCopy: onCopyDNSNameText, children: _jsxs("div", { className: "copy-area", children: [_jsx("button", { className: "btn btn-info mr-1", children: _jsx("i", { className: "far fa-copy" }) }), isDNSNameCopied ? (_jsx("span", { style: { color: "var(--danger)" }, children: "Copied." })) : null] }) }), _jsx("br", {}), _jsx("br", {}), _jsx("b", { children: "Value:" }), " ", _jsx("code", { children: customDomainValidation.ResourceRecord.Value }), _jsx(CopyToClipboard, { text: customDomainValidation.ResourceRecord.Value, onCopy: onCopyDNSValueText, children: _jsxs("div", { className: "copy-area", children: [_jsx("button", { className: "btn btn-info mr-1", children: _jsx("i", { className: "far fa-copy" }) }), isDNSValueCopied ? (_jsx("span", { style: { color: "var(--danger)" }, children: "Copied." })) : null] }) }), _jsx("br", {}), _jsx("br", {}), "Once this is done, click the 'Check Status' button below.", " ", "It could take up to 1 hour to validate the domain,", " ", "so please be patient and feel free to click the 'Check Status' button or refresh this page", " ", "until the domain is validated.", _jsx("br", {}), _jsx("br", {}), _jsx(Button, { onClick: getCustomDomainStatus, disabled: checkingDNSStatus, children: "Check Status" })] })) }), customDomainValidation !== null && customDomainValidation.ValidationStatus === "FAILED" &&
                                    _jsx(Alert, { type: "danger", message: "Sorry, it has been more than 72 hours and the DNS validation has not been able to be validated. Please contact support@ltiaas.com to reset and try again.", dismissible: false }), customDomainValidation !== null && customDomainValidation.ValidationStatus === "SUCCESS" && distributionDomainName === null &&
                                    _jsxs("p", { children: [checkingDNSStatus ? (_jsx("i", { children: "Loading..." })) : (_jsx(_Fragment, { children: "DNS validation was successful. Setting up Custom API service..." })), _jsx("br", {}), _jsx("br", {}), _jsx(Button, { onClick: getCustomDomainStatus, disabled: checkingDNSStatus, children: "Check Status" })] }), customDomainValidation !== null && customDomainValidation.ValidationStatus === "SUCCESS" && distributionDomainName !== null &&
                                    _jsxs("p", { children: ["Please add a CNAME DNS entry to point ", _jsx("b", { children: subdomainName }), " to the LTIAAS API for your domain:", _jsx("br", {}), _jsx("br", {}), _jsx("b", { children: "Value:" }), " ", _jsx("code", { children: distributionDomainName }), _jsx(CopyToClipboard, { text: distributionDomainName, onCopy: onCopyDNSValueText, children: _jsxs("div", { className: "copy-area", children: [_jsx("button", { className: "btn btn-info mr-1", children: _jsx("i", { className: "far fa-copy" }) }), isDNSValueCopied ? (_jsx("span", { style: { color: "var(--danger)" }, children: "Copied." })) : null] }) }), _jsx("br", {}), _jsx("br", {}), _jsx(Alert, { type: "info", message: "Note: LTIAAS needs two CNAME records, one that you created in the previous step to validate the domain, and one for " + subdomainName + ". Both must remain in place for your custom domain to work properly. Do not delete either of them.", dismissible: false }), _jsx("br", {}), _jsx("br", {}), _jsx(Button, { onClick: getCustomDomainStatus, disabled: checkingDNSStatus, children: "Check Status" })] })] })] }), enabled && customDomainValidation !== null && customDomainValidation.ValidationStatus === "SUCCESS" && distributionDomainName !== null && dnsCorrect &&
                _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: "Custom Domain Setup" }), _jsxs("div", { className: "card-body", children: [_jsx("p", { children: "Your custom domain is set up correctly and is live. Note: LTIAAS needs the two CNAME records to remain in place for your custom domain to work properly. Do not delete either of them from your DNS configuration." }), _jsxs("table", { className: "table table-striped table-hover table-sm table-responsive", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: " Name " }), _jsx("th", { children: " Value " })] }) }), _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { children: customDomainValidation.ResourceRecord.Name }), _jsx("td", { children: customDomainValidation.ResourceRecord.Value })] }), _jsxs("tr", { children: [_jsx("td", { children: subdomainName }), _jsx("td", { children: distributionDomainName })] })] })] })] })] })] }));
};
export default CustomDomain;
