// https://canvas.instructure.com/doc/api/file.placements_overview.html
export var placementOptions = [
    {
        label: "Default Placement (All LMSes)",
        value: "default"
    },
    {
        label: "Content Area (Canvas Only)",
        value: "ContentArea"
    },
    {
        label: "Global Navigation (Canvas Only)",
        value: "https://canvas.instructure.com/lti/global_navigation"
    },
    {
        label: "Account Navigation (Canvas Only)",
        value: "https://canvas.instructure.com/lti/account_navigation"
    },
    {
        label: "Course Navigation (Canvas Only)",
        value: "https://canvas.instructure.com/lti/course_navigation"
    },
    {
        label: "Editor Button (Canvas Only)",
        value: "https://canvas.instructure.com/lti/editor_button"
    },
    {
        label: "Assignment Edit (Canvas Only)",
        value: "https://canvas.instructure.com/lti/assignment_edit"
    },
    {
        label: "Assignment Selection (Canvas Only)",
        value: "https://canvas.instructure.com/lti/assignment_selection"
    },
    {
        label: "Assignment View (Canvas Only)",
        value: "https://canvas.instructure.com/lti/assignment_view"
    },
    {
        label: "Homework Submission (Canvas Only)",
        value: "https://canvas.instructure.com/lti/homework_submission"
    },
    {
        label: "Assignment Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/assignment_menu"
    },
    {
        label: "Assignments Index Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/assignment_index_menu"
    },
    {
        label: "Assignments Group Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/assignment_group_menu"
    },
    {
        label: "Collaboration (Canvas Only)",
        value: "https://canvas.instructure.com/lti/collaboration"
    },
    {
        label: "Course Assignments Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/course_assignments_menu"
    },
    {
        label: "Course Home Sub Navigation (Canvas Only)",
        value: "https://canvas.instructure.com/lti/course_home_sub_navigation"
    },
    {
        label: "Course Settings Sub Navigation (Canvas Only)",
        value: "https://canvas.instructure.com/lti/course_settings_sub_navigation"
    },
    {
        label: "Discussion Topic Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/discussion_topic_menu"
    },
    {
        label: "Discussions Index Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/discussion_topic_index_menu"
    },
    {
        label: "File Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/file_menu"
    },
    {
        label: "Files Index Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/file_index_menu"
    },
    {
        label: "Link Selection (Canvas Only)",
        value: "https://canvas.instructure.com/lti/link_selection"
    },
    {
        label: "Migration Selection (Canvas Only)",
        value: "https://canvas.instructure.com/lti/migration_selection"
    },
    {
        label: "Modules Index Menu (Tray) (Canvas Only)",
        value: "https://canvas.instructure.com/lti/module_index_menu"
    },
    {
        label: "Modules Index Menu (Modal) (Canvas Only)",
        value: "https://canvas.instructure.com/lti/module_index_menu_modal"
    },
    {
        label: "Module Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/module_menu"
    },
    {
        label: "Module Group Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/module_group_menu"
    },
    {
        label: "Module Menu Modal (Canvas Only)",
        value: "https://canvas.instructure.com/lti/module_menu_modal"
    },
    {
        label: "Sync Grades (Canvas Only)",
        value: "https://canvas.instructure.com/lti/post_grades"
    },
    {
        label: "Quiz Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/quiz_menu"
    },
    {
        label: "Quizzes Index Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/quiz_index_menu"
    },
    {
        label: "Student Context Card (Canvas Only)",
        value: "https://canvas.instructure.com/lti/student_context_card"
    },
    {
        label: "Submission Type Selection (Restricted) (Canvas Only)",
        value: "https://canvas.instructure.com/lti/submission_type_selection"
    },
    {
        label: "Tool Configuration (Canvas Only)",
        value: "https://canvas.instructure.com/lti/tool_configuration"
    },
    {
        label: "Page Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/wiki_page_menu"
    },
    {
        label: "Pages Index Menu (Canvas Only)",
        value: "https://canvas.instructure.com/lti/wiki_index_menu"
    },
    {
        label: "Top Navigation (Restricted) (Canvas Only)",
        value: "https://canvas.instructure.com/lti/top_navigation"
    }
];
