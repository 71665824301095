import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../FirebaseAuth";
import PublicTemplate from "../../templates/PublicTemplate";
import Loader from "../../Loader";
var PrivateRoute = function () {
    var authUser = useContext(AuthContext).authUser;
    return authUser.checked ? (!!authUser.user ? (_jsx(Outlet, {})) : (_jsx(Navigate, { to: "/signin?re=" + document.location.pathname + document.location.search }))) : (_jsx(PublicTemplate, { children: _jsx(Loader, { size: "5x", text: "Loading..." }) }));
};
export default PrivateRoute;
