var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import Loader from '../../../../components/Loader';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Alert from "../../../../components/Alert";
var PaymentMethod = function () {
    var _a;
    var title = 'Update Payment Method';
    var _b = useContext(AuthContext), userData = _b.userData, authUser = _b.authUser;
    var stripe = useStripe();
    var elements = useElements();
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    var CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        },
        hidePostalCode: true
    };
    var _c = useState(false), processing = _c[0], setProcessing = _c[1];
    var _d = useState(false), success = _d[0], setSuccess = _d[1];
    var _e = useState(null), cardError = _e[0], setCardError = _e[1];
    var _f = useState(null), errorMessage = _f[0], setErrorMessage = _f[1];
    var subcribe = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var hasError, paymentMethodId, cardElement, _a, error, paymentMethod, updatePaymentMethod;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    event.preventDefault();
                    setProcessing(true);
                    setErrorMessage(null);
                    setSuccess(false);
                    hasError = false;
                    paymentMethodId = '';
                    setCardError(null);
                    if (!stripe || !elements) {
                        // Stripe.js has not loaded yet. Make sure to disable
                        // form submission until Stripe.js has loaded.
                        return [2 /*return*/];
                    }
                    cardElement = elements.getElement(CardElement);
                    if (!cardElement) {
                        setCardError('Card element not found');
                        setProcessing(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, stripe.createPaymentMethod({
                            type: 'card',
                            card: cardElement,
                        })];
                case 1:
                    _a = _b.sent(), error = _a.error, paymentMethod = _a.paymentMethod;
                    if (error) {
                        setCardError(error.message || 'Unknown error');
                        ;
                        hasError = true;
                    }
                    else {
                        paymentMethodId = paymentMethod.id;
                    }
                    if (!hasError) {
                        updatePaymentMethod = CloudFunctions.httpsCallable('oncall');
                        updatePaymentMethod({
                            name: "updatePaymentMethod",
                            data: {
                                accountId: userData.currentAccount.id,
                                paymentMethodId: paymentMethodId
                            }
                        }).then(function (res) {
                            setSuccess(true);
                            setProcessing(false);
                        }).catch(function (err) {
                            setProcessing(false);
                            setErrorMessage(err.message);
                        });
                    }
                    else {
                        setProcessing(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/billing",
                text: 'Billing',
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [userData, setBreadcrumb, title]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: userData.currentAccount.price > 0 ? (_jsx(_Fragment, { children: _jsx("div", { className: "card-deck mb-3", children: _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header text-center", children: title }), _jsx("div", { className: "card-body", children: (((_a = authUser.user) === null || _a === void 0 ? void 0 : _a.uid) && userData.currentAccount.owner === authUser.user.uid) ? (_jsxs(_Fragment, { children: [success &&
                                                _jsx(Alert, { type: "success", message: "The payment method has been successfully updated.", dismissible: true, onDismiss: function () { return setSuccess(false); } }), errorMessage !== null &&
                                                _jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } }), _jsx("div", { className: "row justify-content-md-center", children: _jsxs("div", { className: "col col-sm-12 col-md-8 col-lg-8 col-xl-6", children: [_jsx("div", { className: "card-deck", children: _jsxs("div", { className: "card mb-4", children: [_jsx("div", { className: "card-header text-center", children: "Credit or debit card" }), _jsx("div", { className: "card-body", children: _jsx("div", { className: "row justify-content-md-center", children: _jsxs("div", { className: "col col-12", children: [cardError !== null &&
                                                                                        _jsx(Alert, { type: "danger", message: cardError, dismissible: true, onDismiss: function () { return setCardError(null); } }), _jsx(CardElement, { options: CARD_ELEMENT_OPTIONS })] }) }) })] }) }), _jsx("button", { className: "btn btn-lg btn-block btn-primary", disabled: processing ? true : false, onClick: function (e) {
                                                                subcribe(e);
                                                            }, children: processing ? (_jsx(Loader, { text: "Please wait..." })) : (_jsx(_Fragment, { children: "Save" })) })] }) })] })) : (_jsx(Alert, { type: "danger", message: "Access Denied.  You are not the account owner.", dismissible: false })) })] }) }) })) : (_jsx("div", { children: "The account doesn't support payment methods." })) }) }) }));
};
export default PaymentMethod;
