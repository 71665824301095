import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { Form, Field, Input } from '../../../../components/Form';
import firebase from "firebase/compat/app";
import { FirebaseAuth } from '../../../../components/FirebaseAuth/firebase';
import { AuthContext } from '../../../../components/FirebaseAuth';
import Alert from '../../../../components/Alert';
import UserPageLayout from '../../../../components/user/UserPageLayout';
import { log, UPDATE_EMAIL } from "../../../../libs/log";
var UpdateEmail = function () {
    var title = "Change Your Email";
    var _a = useState({
        hasError: false,
        error: null,
        value: ""
    }), emailAddress = _a[0], setEmailAddress = _a[1];
    var _b = useState({
        hasError: false,
        error: null,
        value: ""
    }), password = _b[0], setPassword = _b[1];
    var authUser = useContext(AuthContext).authUser;
    var _c = useState({
        status: null,
        message: ''
    }), result = _c[0], setResult = _c[1];
    var _d = useState(false), inSubmit = _d[0], setInSubmit = _d[1];
    return (_jsxs(UserPageLayout, { title: title, children: [result.status === null &&
                _jsxs(Form, { handleSubmit: function (e) {
                        var _a;
                        e.preventDefault();
                        setInSubmit(true);
                        var user = FirebaseAuth.auth().currentUser;
                        if (user === null) {
                            setResult({
                                status: false,
                                message: 'You are not logged in.'
                            });
                            setInSubmit(false);
                            return;
                        }
                        // check password
                        var credential = firebase.auth.EmailAuthProvider.credential(user.email || "", // login will fail if no email address
                        password.value);
                        // update email address
                        (_a = authUser.user) === null || _a === void 0 ? void 0 : _a.reauthenticateWithCredential(credential).then(function () {
                            var _a;
                            (_a = authUser.user) === null || _a === void 0 ? void 0 : _a.updateEmail(emailAddress.value).then(function () {
                                var _a;
                                (_a = authUser.user) === null || _a === void 0 ? void 0 : _a.sendEmailVerification().then(function () {
                                    log(UPDATE_EMAIL);
                                    setResult({
                                        status: true,
                                        message: 'Your email address has been updated. Please check your email inbox to verify the email address.'
                                    });
                                    setInSubmit(false);
                                }).catch(function () {
                                    setResult({
                                        status: true,
                                        message: 'Your email address has been updated. Please verify your email.'
                                    });
                                    setInSubmit(false);
                                });
                            }).catch(function (err) {
                                setResult({
                                    status: false,
                                    message: err.message
                                });
                                setInSubmit(false);
                            });
                        }).catch(function () {
                            setPassword({
                                hasError: true,
                                error: 'Incorrect password, authentication failed.',
                                value: password.value
                            });
                            setInSubmit(false);
                        });
                    }, disabled: emailAddress.hasError || password.hasError || emailAddress.value === "" || password.value === "" || inSubmit, inSubmit: inSubmit, enableDefaultButtons: true, backToUrl: "/user/profile", children: [_jsx(Field, { label: "Email Address", children: _jsx(Input, { type: "email", name: "email-address", hasError: emailAddress.hasError, error: emailAddress.error, minLen: 5, maxLen: 50, required: true, validRegex: "^[a-zA-Z0-9-_+\\.]*@[a-zA-Z0-9-_\\.]*\\.[a-zA-Z0-9-_\\.]*$", changeHandler: setEmailAddress }) }), _jsx(Field, { label: "Current Password", children: _jsx(Input, { type: "password", name: "password", hasError: password.hasError, error: password.error, required: true, changeHandler: setPassword }) })] }), result.status === false &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "danger", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx("button", { className: "btn btn-primary mr-2", onClick: function () {
                                setResult({
                                    status: null,
                                    message: ''
                                });
                            }, children: "Try Again" }), _jsx(Link, { className: "btn btn-secondary", to: "/user/profile", children: "View Profile" })] }), result.status === true &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "success", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx(Link, { className: "btn btn-primary", to: "/user/profile", children: "View Profile" })] })] }));
};
export default UpdateEmail;
