import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { Form, Field, Input } from '../../../../components/Form';
import { Navigate } from 'react-router-dom';
import Alert from "../../../../components/Alert";
var NewAccount = function () {
    var title = 'Create New Account';
    var _a = useState({
        hasError: false,
        error: null,
        value: ""
    }), accountName = _a[0], setAccountName = _a[1];
    var _b = useState({
        hasError: false,
        error: null,
        value: "provider"
    }), accountType = _b[0], setAccountType = _b[1];
    var _c = useState(null), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(false), inSubmit = _d[0], setInSubmit = _d[1];
    var _e = useState(null), redirect = _e[0], setRedirect = _e[1];
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb, title]);
    return (_jsxs(_Fragment, { children: [redirect === null &&
                _jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), errorMessage !== null &&
                                        _jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } }), _jsx("div", { className: "card-body", children: _jsxs(Form, { handleSubmit: function (e) {
                                                e.preventDefault();
                                                setInSubmit(true);
                                                setErrorMessage(null);
                                                var createAccount = CloudFunctions.httpsCallable('oncall');
                                                createAccount({
                                                    name: "createAccount",
                                                    data: {
                                                        accountName: accountName.value,
                                                        accountType: accountType.value
                                                    }
                                                }).then(function (response) {
                                                    var accountId = response.data.accountId;
                                                    setRedirect('/account/' + accountId + '/billing/plan');
                                                }).catch(function (err) {
                                                    setErrorMessage(err.message);
                                                    setInSubmit(false);
                                                });
                                            }, disabled: accountName.hasError || accountName.value === "" || accountType.hasError || accountType.value === null || inSubmit, inSubmit: inSubmit, enableDefaultButtons: true, children: [false &&
                                                    _jsxs(Field, { label: "Account Type", children: [_jsxs("p", { children: ["Choose ", _jsx("b", { children: "LTI Tool" }), " if you are developing a learning tool that plans to integrate into learning management systems (LMS). Choose ", _jsx("b", { children: "LTI Platform" }), " if you plan to develop an LMS or other service that wants to integrate with LTI tools."] }), _jsxs("div", { className: "toggle", children: [_jsx(Input, { type: "radio", name: "account-type", value: "provider", required: true, changeHandler: setAccountType, id: "accountTypeProvider", checked: accountType.value === "provider" }), _jsx("label", { htmlFor: "accountTypeProvider", children: "LTI Tool" }), _jsx(Input, { type: "radio", name: "account-type", value: "consumer", required: true, changeHandler: setAccountType, id: "accountTypeConsumer", checked: accountType.value === "consumer" }), _jsx("label", { htmlFor: "accountTypeConsumer", children: "LTI Platform" })] })] }), _jsxs(Field, { label: "Account Name", children: [_jsx("p", { children: "Give your account a name of your choosing. This name is only used by you and helps differentiate your API services if you have multiple." }), _jsx(Input, { type: "text", name: "account-name", maxLen: 100, required: true, changeHandler: setAccountName })] })] }) })] }) }) }) }), redirect !== null &&
                _jsx(Navigate, { to: redirect })] }));
};
export default NewAccount;
