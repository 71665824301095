import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { FirebaseAuth } from "./firebase";
export var AuthContext = React.createContext({
    authUser: { user: null, checked: false },
    userData: {},
    setUserData: function (data) { }
});
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useState({
        'user': null,
        'checked': false
    }), authUser = _b[0], setAuthUser = _b[1];
    var _c = useState({}), userData = _c[0], setUserData = _c[1];
    useEffect(function () {
        FirebaseAuth.auth().onAuthStateChanged(function (user) {
            if (user !== null) {
                user.getIdToken().then(function (token) {
                    setAuthUser({
                        'user': user,
                        'checked': true
                    });
                });
            }
            else {
                setAuthUser({
                    'user': null,
                    'checked': true
                });
            }
        });
    }, []);
    return (_jsx(AuthContext.Provider, { value: {
            authUser: authUser,
            userData: userData,
            setUserData: setUserData
        }, children: children }));
};
