import { useState, useCallback, useEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorker";
export var useServiceWorker = function () {
    var _a = useState(null), waitingWorker = _a[0], setWaitingWorker = _a[1];
    var _b = useState(false), showReload = _b[0], setShowReload = _b[1];
    // called when a service worker
    // updates. this function is a callback
    // to the actual service worker
    // registration onUpdate.
    var onSWUpdate = useCallback(function (registration) {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    }, []);
    // simply put, this tells the service
    // worker to skip the waiting phase and then reloads the page
    var reloadPage = useCallback(function () {
        waitingWorker === null || waitingWorker === void 0 ? void 0 : waitingWorker.postMessage({ type: "SKIP_WAITING" });
        setShowReload(false);
        window.location.reload();
    }, [waitingWorker]);
    // register the service worker
    useEffect(function () {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://cra.link/PWA
        serviceWorkerRegistration.register({
            onUpdate: onSWUpdate,
        });
    }, [onSWUpdate]);
    return { showReload: showReload, waitingWorker: waitingWorker, reloadPage: reloadPage };
};
