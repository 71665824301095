import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import PropTypes from "prop-types";
var Field = function (props) {
    return (_jsxs("div", { className: "form-group row", children: [_jsxs("label", { className: "col-md-2 col-lg-2 col-form-label", children: [_jsx("strong", { children: props.label }), props.logo && _jsx("div", { style: { "textAlign": "center", "height": "max(calc(100% - 15px), 60px)" }, className: "rounded-top bg-light", children: _jsx("img", { alt: "LTIAAS Logo", style: { "maxHeight": "100%", "maxWidth": "100%", "marginTop": "5px" }, src: props.logo }) })] }), _jsxs("div", { className: "col-md-10 col-lg-10", children: [props.description != null && _jsx("p", { children: props.description }), props.extra != null && _jsx("div", { className: "alert alert-info", role: "alert", children: props.extra }), props.children] })] }));
};
Field.propTypes = {
    label: PropTypes.string
};
export default Field;
