import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import { Form, Field, Input } from "../../../../components/Form";
import 'react-quill/dist/quill.snow.css';
// Similar comp to the one described here:
// https://www.joshwcomeau.com/react/the-perils-of-rehydration/#abstractions
function BrowserOnly(_a) {
    var children = _a.children, fallback = _a.fallback;
    return _jsx(_Fragment, { children: children === null || children === void 0 ? void 0 : children() });
}
var modules = {
    toolbar: [
        [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link']['clean'],
    ],
};
var formats = [
    'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'link'
];
var PrioritySupport = function () {
    var userData = useContext(AuthContext).userData;
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    var _a = useState({
        hasError: false,
        error: null,
        value: ""
    }), title = _a[0], setTitle = _a[1];
    var _b = useState(""), message = _b[0], setMessage = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(false), success = _d[0], setSuccess = _d[1];
    var _e = useState(false), inSubmit = _e[0], setInSubmit = _e[1];
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false
            },
            {
                to: null,
                text: "Priority Support",
                active: true
            }
        ]);
    }, [userData, setBreadcrumb]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: "Priority Support" }), _jsxs("div", { className: "card-body", children: [_jsx("p", { children: "Send a message for Priority Support." }), success ? (_jsx(Alert, { type: "success", message: "Your message was sent. Please expect a response via email in less than 24 hours.", dismissible: false, onDismiss: function () { return setSuccess(false); } })) : (_jsxs(_Fragment, { children: [error !== null &&
                                            _jsx(Alert, { type: "danger", message: error, dismissible: true, onDismiss: function () { return setError(null); } }), _jsxs(Form, { handleSubmit: function (e) {
                                                e.preventDefault();
                                                setError(null);
                                                setSuccess(false);
                                                setInSubmit(true);
                                                var sendPrioritySupportMessage = CloudFunctions.httpsCallable('oncall');
                                                sendPrioritySupportMessage({
                                                    name: "sendPrioritySupportMessage",
                                                    data: {
                                                        accountId: userData.currentAccount.id,
                                                        title: title.value,
                                                        message: message
                                                    }
                                                }).then(function (res) {
                                                    setInSubmit(false);
                                                    setSuccess(true);
                                                }).catch(function (err) {
                                                    setInSubmit(false);
                                                    setError(err.message);
                                                });
                                            }, disabled: title.hasError || title.value === "" || message.length === 0 || inSubmit, submitBtnStyle: "primary", inSubmit: inSubmit, enableDefaultButtons: true, children: [_jsx(Field, { label: "Title", children: _jsx(Input, { type: "text", name: "title", hasError: title.hasError, error: title.error, required: true, validRegex: "^.*$", changeHandler: setTitle }) }), _jsx(Field, { label: "Message", children: _jsx(BrowserOnly, { fallback: _jsx("div", { children: "Loading..." }), children: function () {
                                                            var ReactQuill = require('react-quill');
                                                            return _jsx(ReactQuill, { theme: "snow", name: "message", value: message, onChange: setMessage, modules: modules, formats: formats });
                                                        } }) })] })] }))] })] }) }) }) }));
};
export default PrioritySupport;
