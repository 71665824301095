import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import Loader from '../../../../components/Loader';
var ViewLogs = function () {
    var pageSize = 10;
    var _a = useState(0), total = _a[0], setTotal = _a[1];
    var getTotal = function () {
        var user = FirebaseAuth.auth().currentUser;
        if (user !== null) {
            var userDocRef = FirebaseAuth.firestore().collection('users').doc(user.uid);
            userDocRef.get().then(function (userDoc) {
                if (userDoc.exists) {
                    var userData = userDoc.data();
                    if (userData !== undefined) {
                        setTotal(userData.activityCount);
                    }
                }
            });
        }
    };
    // document snapshots
    var _b = useState(null), qs = _b[0], setQs = _b[1];
    var _c = useState([]), rows = _c[0], setRows = _c[1];
    var _d = useState(0), count = _d[0], setCount = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var getLogs = function (pageSize, lastDoc) {
        setLoading(true);
        var records = [];
        var user = FirebaseAuth.auth().currentUser;
        if (user === null) {
            return;
        }
        var collectionRef = FirebaseAuth.firestore().collection('users').doc(user.uid).collection('activities');
        var query = collectionRef.orderBy('time', 'desc');
        if (lastDoc) {
            query = query.startAfter(lastDoc);
        }
        query = query.limit(pageSize);
        query.get().then(function (documentSnapshots) {
            documentSnapshots.forEach(function (doc) {
                records.push({
                    'timestamp': doc.id,
                    'time': doc.data().time.toDate().toLocaleString(),
                    'action': doc.data().action
                });
            });
            if (records.length > 0) {
                setRows(function (rows) { return rows.concat(records); });
                setQs(documentSnapshots);
                setCount(function (count) { return documentSnapshots.size + count; });
            }
            setLoading(false);
        });
    };
    var title = 'View Activity Logs';
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/user/profile",
                text: "User",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        getTotal();
        getLogs(pageSize);
    }, [setBreadcrumb]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), _jsx("div", { className: "card-body", children: total > 0 ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "col-sm-12 table-responsive", children: _jsxs("table", { className: "table table-responsive-sm table-hover table-outline", children: [_jsx("thead", { className: "thead-light", children: _jsxs("tr", { role: "row", children: [_jsx("th", { children: "Activity" }), _jsx("th", { children: "Time" })] }) }), _jsx("tbody", { children: rows.map(function (r, i) {
                                                        return _jsxs("tr", { children: [_jsx("td", { children: r.action }), _jsx("td", { children: r.time })] }, r.timestamp + i);
                                                    }) })] }) }), _jsxs("div", { className: "row", children: [_jsxs("div", { className: "col-5", children: [count, " of ", total] }), _jsx("div", { className: "col-7 text-right", children: _jsxs("button", { className: "btn btn-primary", disabled: (total === count || loading), onClick: function (e) {
                                                        e.preventDefault();
                                                        if (qs !== null) {
                                                            getLogs(pageSize, qs.docs[qs.docs.length - 1]);
                                                        }
                                                    }, children: [loading && _jsx(Loader, { size: "sm", text: "" }), " More activities..."] }) })] })] })) : (_jsx(_Fragment, { children: (qs === null) ? (_jsx(Loader, { text: "loading logs...", size: "sm" })) : (_jsx("div", { children: "No activity is found" })) })) })] }) }) }) }));
};
export default ViewLogs;
