import { FirebaseAuth } from "../../components/FirebaseAuth/firebase";
import { log, SIGN_IN, SIGN_OUT, UPDATE_USERNAME } from '../log';
export var userSignIn = function (callback) {
    var dt = new Date();
    var Firestore = FirebaseAuth.firestore();
    var currentUser = FirebaseAuth.auth().currentUser;
    if (currentUser !== null) {
        var userDocRef_1 = Firestore.collection('users').doc(currentUser.uid);
        userDocRef_1.get().then(function (doc) {
            if (doc.exists) {
                // update user document
                userDocRef_1.set({
                    displayName: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    lastLoginTime: dt
                }, { merge: true }).then(function () {
                    callback(true);
                }).catch(function (err) {
                    //console.log(err);
                    callback(false);
                });
            }
            else {
                // create user document
                userDocRef_1.set({
                    displayName: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    creationTime: dt,
                    lastLoginTime: dt
                }).then(function () {
                    callback(true);
                }).catch(function (err) {
                    //console.log(err);
                    callback(false);
                });
            }
        });
        log(SIGN_IN);
    }
};
export var userSignOut = function () {
    log(SIGN_OUT, function (result) {
        // wait for log is successfully written before signing out
        if (result) {
            FirebaseAuth.auth().signOut();
        }
    });
};
export var userUpdateName = function () {
    var Firestore = FirebaseAuth.firestore();
    var currentUser = FirebaseAuth.auth().currentUser;
    if (currentUser !== null) {
        var userDocRef = Firestore.collection('users').doc(currentUser.uid);
        userDocRef.set({
            displayName: currentUser.displayName
        }, { merge: true });
        log(UPDATE_USERNAME);
    }
};
