import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import UserMenu from '../../menus/UserMenu';
import AppMenu from '../../menus/AppMenu';
import Logo from '../../Logo';
import { BreadcrumbContext, Breadcrumb } from '../../Breadcrumb';
var AppTemplate = function (_a) {
    var children = _a.children;
    var _b = useState([]), breadcrumb = _b[0], setBreadcrumb = _b[1];
    return (_jsxs("div", { className: "c-app", children: [_jsxs("div", { className: "c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show", id: "sidebar", children: [_jsx("div", { className: "c-sidebar-brand d-md-down-none", children: _jsx(Logo, {}) }), _jsx(AppMenu, {}), _jsx("button", { className: "c-sidebar-minimizer c-class-toggler", "data-target": "_parent", "data-class": "c-sidebar-minimized", type: "button" })] }), _jsxs("div", { className: "c-wrapper", children: [_jsxs("header", { className: "c-header c-header-light c-header-fixed", children: [_jsx("button", { className: "c-header-toggler c-class-toggler d-lg-none mfe-auto", type: "button", "data-target": "#sidebar", "data-class": "c-sidebar-show", children: _jsx("i", { className: "mt-3 fa fa-bars" }) }), _jsx("div", { children: _jsx("button", { className: "c-header-toggler c-class-toggler mfs-3 d-md-down-none", "data-target": "#sidebar", "data-class": "c-sidebar-lg-show", children: _jsx("i", { className: "mt-3 fa fa-bars" }) }) }), _jsx(Breadcrumb, { links: breadcrumb }), _jsx("ul", { className: "c-header-nav", children: _jsx(UserMenu, {}) })] }), _jsx("div", { className: "c-body", children: _jsx("main", { className: "c-main", children: _jsx(BreadcrumbContext.Provider, { value: { setBreadcrumb: setBreadcrumb }, children: children }) }) })] })] }));
};
export default AppTemplate;
