import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { Form, Field, Input } from '../../../../components/Form';
import { AuthContext } from '../../../../components/FirebaseAuth';
import Alert from '../../../../components/Alert';
import UserPageLayout from '../../../../components/user/UserPageLayout';
import { userUpdateName } from '../../../../libs/user';
var UpdateName = function () {
    var title = "Change Your Name";
    var _a = useState({
        hasError: false,
        error: null,
        value: ""
    }), fullname = _a[0], setFullname = _a[1];
    var authUser = useContext(AuthContext).authUser;
    var _b = useState({
        status: null,
        message: ''
    }), result = _b[0], setResult = _b[1];
    var _c = useState(false), inSubmit = _c[0], setInSubmit = _c[1];
    return (_jsxs(UserPageLayout, { title: title, children: [result.status === null &&
                _jsx(Form, { handleSubmit: function (e) {
                        var _a;
                        e.preventDefault();
                        (_a = authUser.user) === null || _a === void 0 ? void 0 : _a.updateProfile({
                            displayName: fullname.value
                        }).then(function () {
                            userUpdateName();
                            setResult({
                                status: true,
                                message: 'Your name has been updated.'
                            });
                            setInSubmit(false);
                        }).catch(function (err) {
                            setResult({
                                status: false,
                                message: err.message
                            });
                            setInSubmit(false);
                        });
                    }, disabled: fullname.hasError || fullname.value === "" || inSubmit, inSubmit: inSubmit, enableDefaultButtons: true, backToUrl: "/user/profile", children: _jsx(Field, { label: "Your Name", children: _jsx(Input, { type: "text", name: "full-name", maxLen: 100, required: true, changeHandler: setFullname }) }) }), result.status === false &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "danger", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx("button", { className: "btn btn-primary mr-2", onClick: function () {
                                setResult({
                                    status: null,
                                    message: ''
                                });
                            }, children: "Try Again" }), _jsx(Link, { className: "btn btn-secondary", to: "/user/profile", children: "View Profile" })] }), result.status === true &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "success", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx(Link, { className: "btn btn-primary", to: "/user/profile", children: "View Profile" })] })] }));
};
export default UpdateName;
