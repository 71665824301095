import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Field, InputSubdomain } from "../Form";
import { Form, Modal } from "react-bootstrap";
var SubdomainField = function (props) {
    var services = props.services, settingsCommitted = props.settingsCommitted, subdomainName = props.subdomainName, setSubdomainName = props.setSubdomainName, zoneName = props.zoneName, zoneOptions = props.zoneOptions;
    var subdomainInputElement = React.useRef(null);
    var _a = React.useState(false), locked = _a[0], setLocked = _a[1];
    var _b = React.useState(false), showDialog = _b[0], setShowDialog = _b[1];
    var _c = React.useState(false), question1 = _c[0], setQuestion1 = _c[1];
    var _d = React.useState(false), question2 = _d[0], setQuestion2 = _d[1];
    var _e = React.useState(false), question3 = _e[0], setQuestion3 = _e[1];
    var _f = React.useState(".ltiaas.com"), domain = _f[0], setDomain = _f[1];
    function customDomainChangeHandler(event) {
        var new_val = event.value;
        var foundErrorMessage = "";
        var foundError = false;
        // validate the value against validation regex
        if (!RegExp("^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\\.?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\\.?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\\.(xn--)?([a-z0-9\\-]{1,61}|[a-z0-9-]{1,30}\\.[a-z]{2,})$").test(new_val)) {
            foundErrorMessage = "Must be a valid domain or subdomain name. For example: lti.mytool.com";
            foundError = true;
        }
        if (!foundError && new_val.endsWith(".ltiaas.com")) {
            validateSubdomain(new_val);
        }
        else {
            setSubdomainName({
                value: new_val,
                hasError: foundError,
                error: foundErrorMessage
            });
        }
    }
    function subdomainKeyDownHandler(event) {
        event.preventDefault();
        var val = subdomainName.value;
        var start = val.indexOf(domain);
        var new_val = "";
        if (event.keyCode === 8) {
            //backspace
            if (start > 0) {
                new_val = val.substring(0, start - 1) + domain;
            }
            else {
                new_val = subdomainName.value;
            }
        }
        else if (event.key.length === 1) {
            new_val = val.substring(0, start) + event.key + domain;
        }
        else {
            new_val = val;
        }
        validateSubdomain(new_val);
    }
    function validateSubdomain(new_val) {
        var foundError = false;
        var foundErrorMessage = "";
        // validate required
        if (new_val.trim().length === 0) {
            foundErrorMessage = "This is a required field.";
            foundError = true;
        }
        if (new_val.length > 61) {
            foundErrorMessage =
                "The input must be no more than " + 61 + " characters.";
            foundError = true;
        }
        // validate the value against validation regex
        if (!RegExp("^[a-z0-9\\-\\.]{3,60}".concat(domain, "$")).test(new_val)) {
            foundErrorMessage = "Must be a subdomain of ".concat(domain, ". i.e.: mytool").concat(domain, " where the subdomains is 3-50 characters.");
            foundError = true;
        }
        setSubdomainName({
            value: new_val,
            hasError: foundError,
            error: foundErrorMessage
        });
    }
    React.useEffect(function () {
        if (services.customSubdomain && subdomainName.value.length > 0 && zoneOptions.length > 0) {
            customDomainChangeHandler(subdomainName);
        }
    }, [zoneName.value]);
    React.useEffect(function () {
        var val = subdomainName.value;
        if (val && !services.customSubdomain) {
            var foundZone = zoneOptions.find(function (z) { return z.value === zoneName.value; });
            var foundDomain = foundZone ? foundZone.prefix : ".ltiaas.com";
            var start = val.indexOf(foundDomain);
            if (subdomainInputElement.current) {
                subdomainInputElement.current.selectionStart = start;
                subdomainInputElement.current.selectionEnd = start;
            }
            setDomain(foundDomain);
        }
    }, [subdomainName, services.customSubdomain, zoneName.value]);
    React.useEffect(function () {
        if (settingsCommitted) {
            setLocked(true);
        }
    }, [settingsCommitted]);
    var requestUnlock = function (value) {
        if (value === true) {
            setShowDialog(true);
        }
        else {
            setLocked(true);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Field, { label: services.customSubdomain ? "Domain Name" : "Subdomain", description: services.customSubdomain ?
                    "This is the URL that we provision for your tool in our system. All of your API traffic will go through this URL. i.e. lti.mytool.com  (or mytool".concat(domain, "). ")
                    :
                        "This is the URL that we provision for your tool in our system. All of your API traffic will go through this URL. It is a subdomain of ltiaas.com. i.e. mytool".concat(domain, "."), children: _jsx(InputSubdomain, { value: subdomainName.value, name: "subdomain-name", maxLen: services.customSubdomain ? 200 : 61, disabled: settingsCommitted && locked, locked: locked, requestUnlock: requestUnlock, error: subdomainName.error, hasError: subdomainName.hasError, required: true, changeHandler: services.customSubdomain ? customDomainChangeHandler : setSubdomainName, keyDownHandler: services.customSubdomain ? function () { } : subdomainKeyDownHandler, ref: subdomainInputElement }) }), _jsxs(Modal, { show: showDialog, onHide: function () {
                    setShowDialog(false);
                }, children: [_jsx(Modal.Header, { closeButton: true, children: _jsx(Modal.Title, { children: "Unlock Domain" }) }), _jsxs(Modal.Body, { children: ["Are you sure you want to change the domain of this account? If you change the domain, this means:", _jsxs(Form, { children: [!services.customSubdomain &&
                                        _jsx(Form.Check, { className: "checkbox-xl", id: "q1", label: "Other customers/accounts will be able to use your old domain.", onChange: function (e) { return setQuestion1(e.target.checked); } }), _jsx(Form.Check, { className: "checkbox-xl", id: "q2", label: "Any existing LMS registrations will no longer work, because LTI registrations are tied to the domain.", onChange: function (e) { return setQuestion2(e.target.checked); } }), services.customSubdomain &&
                                        _jsx(Form.Check, { className: "checkbox-xl", id: "q3", label: "You will need to restart the custom domain validation process for the new domain.", onChange: function (e) { return setQuestion3(e.target.checked); } })] })] }), _jsxs(Modal.Footer, { children: [_jsx("button", { type: "button", className: "btn btn-danger", disabled: !((services.customSubdomain || question1) && question2 && (!services.customSubdomain || question3)), onClick: function (e) {
                                    e.preventDefault();
                                    setLocked(false);
                                    setShowDialog(false);
                                }, children: "Unlock" }), _jsx("button", { type: "button", className: "btn btn-secondary", onClick: function (e) {
                                    e.preventDefault();
                                    setShowDialog(false);
                                }, children: "Cancel" })] })] })] }));
};
export default SubdomainField;
