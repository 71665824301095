var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { CloudFunctions } from "../../../components/FirebaseAuth/firebase";
import { Button, Form, InputGroup, Tooltip } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
export var RenameableHeader = function (_a) {
    var account = _a.account, getAccounts = _a.getAccounts;
    var _b = useState(false), showRenameForm = _b[0], setShowRenameForm = _b[1];
    var _c = useState(false), savingAccountName = _c[0], setSavingAccountName = _c[1];
    var _d = useState(""), selectedAccountName = _d[0], setSelectedAccountName = _d[1];
    var saveAccountName = function () { return __awaiter(void 0, void 0, void 0, function () {
        var changeAccountName;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSavingAccountName(true);
                    changeAccountName = CloudFunctions.httpsCallable('oncall');
                    return [4 /*yield*/, changeAccountName({
                            name: "changeAccountName",
                            data: {
                                accountId: account.id,
                                name: selectedAccountName
                            }
                        })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getAccounts()];
                case 2:
                    _a.sent();
                    setSavingAccountName(false);
                    setShowRenameForm(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        setSelectedAccountName(account.name);
    }, [account]);
    var rename_tooltip = (_jsx(Tooltip, { id: "tooltip", children: "Rename Account" }));
    return (_jsx(_Fragment, { children: (showRenameForm) ?
            _jsx(_Fragment, { children: _jsxs(InputGroup, { children: [_jsx(Form.Control, { type: "text", disabled: savingAccountName, value: selectedAccountName, onChange: function (e) { return setSelectedAccountName(e.target.value); } }), savingAccountName ? (_jsx(Button, { variant: "success", disabled: true, children: _jsx("i", { className: "fa fa-spinner fa-spin" }) })) : (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "success", onClick: function () { saveAccountName(); }, children: _jsx("i", { className: "fa fa-save" }) }), _jsx(Button, { variant: "secondary", onClick: function () { setShowRenameForm(false); }, children: _jsx("i", { className: "fa fa-times" }) })] }))] }) })
            :
                _jsxs(_Fragment, { children: [account.name, " ", _jsx(OverlayTrigger, { placement: "top", overlay: rename_tooltip, children: _jsx(Button, { variant: "light", onClick: function () { setShowRenameForm(true); }, children: _jsx("i", { className: "fa fa-edit" }) }) })] }) }));
};
