import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { Form, Field, Input } from '../../../../components/Form';
import firebase from "firebase/compat/app";
import { FirebaseAuth } from '../../../../components/FirebaseAuth/firebase';
import { AuthContext } from '../../../../components/FirebaseAuth';
import Alert from '../../../../components/Alert';
import UserPageLayout from '../../../../components/user/UserPageLayout';
import { log, UPDATE_PASSWORD } from "../../../../libs/log";
var UpdatePassword = function () {
    var title = "Change Your Password";
    var _a = useState({
        hasError: false,
        error: null,
        value: ""
    }), password = _a[0], setPassword = _a[1];
    var _b = useState({
        hasError: false,
        error: null,
        value: ""
    }), newPassword = _b[0], setNewPassword = _b[1];
    var _c = useState({
        hasError: false,
        error: null,
        value: ""
    }), confirmPassword = _c[0], setConfirmPassword = _c[1];
    var authUser = useContext(AuthContext).authUser;
    var _d = useState({
        status: null,
        message: ''
    }), result = _d[0], setResult = _d[1];
    var _e = useState(false), inSubmit = _e[0], setInSubmit = _e[1];
    return (_jsxs(UserPageLayout, { title: title, children: [result.status === null &&
                _jsxs(Form, { handleSubmit: function (e) {
                        var _a;
                        e.preventDefault();
                        setInSubmit(true);
                        if (newPassword.value !== confirmPassword.value) {
                            setConfirmPassword({
                                hasError: true,
                                error: 'The confirm password does not match with the new password.',
                                value: password.value
                            });
                            setInSubmit(false);
                        }
                        else {
                            var user_1 = FirebaseAuth.auth().currentUser;
                            if (user_1 === null) {
                                setResult({
                                    status: false,
                                    message: 'You are not logged in.'
                                });
                                setInSubmit(false);
                                return;
                            }
                            // check password
                            var credential = firebase.auth.EmailAuthProvider.credential(user_1.email || "", //login will fail if email is missing
                            password.value);
                            // update email address
                            (_a = authUser.user) === null || _a === void 0 ? void 0 : _a.reauthenticateWithCredential(credential).then(function () {
                                user_1.updatePassword(newPassword.value)
                                    .then(function () {
                                    log(UPDATE_PASSWORD);
                                    setResult({
                                        status: true,
                                        message: 'Your password has been updated.'
                                    });
                                    setInSubmit(false);
                                }).catch(function (err) {
                                    setResult({
                                        status: false,
                                        message: err.message
                                    });
                                    setInSubmit(false);
                                });
                            }).catch(function () {
                                setPassword({
                                    hasError: true,
                                    error: 'Incorrect password, authentication failed.',
                                    value: password.value
                                });
                                setInSubmit(false);
                            });
                        }
                    }, disabled: password.hasError || newPassword.hasError || confirmPassword.hasError || password.value === "" || newPassword.value === "" || confirmPassword.value === "" || inSubmit, inSubmit: inSubmit, enableDefaultButtons: true, backToUrl: "/user/profile", children: [_jsx(Field, { label: "Current Password", children: _jsx(Input, { type: "password", name: "password", hasError: password.hasError, error: password.error, required: true, changeHandler: setPassword }) }), _jsx(Field, { label: "New Password", children: _jsx(Input, { type: "password", name: "newPassword", hasError: newPassword.hasError, error: newPassword.error, required: true, minLen: 6, maxLen: 20, changeHandler: setNewPassword }) }), _jsx(Field, { label: "Confirm Password", children: _jsx(Input, { type: "password", name: "confirmPassword", hasError: confirmPassword.hasError, error: confirmPassword.error, required: true, minLen: 6, maxLen: 20, changeHandler: setConfirmPassword }) })] }), result.status === false &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "danger", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx("button", { className: "btn btn-primary mr-2", onClick: function () {
                                setResult({
                                    status: null,
                                    message: ''
                                });
                            }, children: "Try Again" }), _jsx(Link, { className: "btn btn-secondary", to: "/user/profile", children: "View Profile" })] }), result.status === true &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "success", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx(Link, { className: "btn btn-primary", to: "/user/profile", children: "View Profile" })] })] }));
};
export default UpdatePassword;
