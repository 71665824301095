import { FirebaseAuth } from "../../components/FirebaseAuth/firebase";
export var log = function (action, callback) {
    var Firestore = FirebaseAuth.firestore();
    var currentUser = FirebaseAuth.auth().currentUser;
    var dt = new Date();
    var data = {
        'action': action,
        'time': dt
    };
    if (currentUser !== null) {
        var userDocRef = Firestore.collection('users').doc(currentUser.uid);
        userDocRef.collection('activities').doc('' + dt.getTime()).set(data).then(function () {
            if (callback) {
                callback(true);
            }
        }).catch(function (err) {
            if (callback) {
                callback(false);
            }
        });
    }
};
export var SIGN_IN = 'signed in';
export var UPDATE_PASSWORD = 'changed password';
export var UPDATE_USERNAME = 'changed user name';
export var UPDATE_PHONE = 'changed phone number';
export var UPDATE_EMAIL = 'changed email address';
export var SIGN_OUT = 'signed out';
