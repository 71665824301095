import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
export var BreadcrumbContext = React.createContext({ setBreadcrumb: function (links) { } });
export var Breadcrumb = function (_a) {
    var links = _a.links;
    return (_jsx("ol", { className: "mfe-auto breadcrumb border-0 m-0 px-0 px-md-3", children: links !== null && links.map(function (link, key) {
            var txtArray = link.text.split("(");
            var link_id = "";
            var link_text = link.text;
            if (txtArray.length > 1) {
                link_id = "(" + txtArray[txtArray.length - 1];
                link_text = txtArray.slice(0, -1).join("(");
            }
            return (_jsxs("li", { className: link.active ? "active breadcrumb-item" : "breadcrumb-item", children: [link.to !== null &&
                        _jsxs(Link, { to: link.to, children: [link_text, _jsx("i", { style: { color: "#8a93a2" }, children: link_id })] }), link.to === null &&
                        _jsx(_Fragment, { children: link.text })] }, key));
        }) }));
};
export default Breadcrumb;
