import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useServiceWorker } from './useServiceWorker';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
function RefreshButton() {
    // refresh page message
    var _a = useServiceWorker(), waitingWorker = _a.waitingWorker, showReload = _a.showReload, reloadPage = _a.reloadPage;
    React.useEffect(function () {
        if (showReload && waitingWorker) {
            var Msg = function () { return (_jsxs("div", { children: [_jsx("h4", { children: "New Version Available" }), "A new version of this page is available.", " ", _jsx(Button, { variant: "outline-primary", size: "sm", onClick: function () { reloadPage(); }, children: "Refresh Now" })] })); };
            toast.info(_jsx(Msg, {}), {
                position: "bottom-right",
                autoClose: false,
                closeOnClick: false,
                theme: "light",
                toastId: 1
            });
        }
        else {
            toast.dismiss(1);
        }
    }, [waitingWorker, showReload, reloadPage]);
    return (_jsx(ToastContainer, {}));
}
export default RefreshButton;
