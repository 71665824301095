var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { BreadcrumbContext } from '../../../components/Breadcrumb';
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { Link, Navigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { AuthContext } from "../../../components/FirebaseAuth";
import { Accordion, Button } from "react-bootstrap";
import AlertPreformatted from "../../../components/AlertPreformatted";
import { superAdmins } from "../superAdmins";
import Plot from 'react-plotly.js';
import Placeholder from 'react-bootstrap/Placeholder';
import { AdminTable } from "./AdminTable";
import { RenameableHeader } from "./RenameableHeader";
import { UserAccountTable } from "./UserAccountTable";
var Home = function () {
    var _a, _b, _c;
    var title = 'My Accounts';
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    var authUser = useContext(AuthContext).authUser;
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var _e = useState([]), accounts = _e[0], setAccounts = _e[1];
    var _f = useState(null), stats = _f[0], setStats = _f[1];
    var _g = useState([]), closedAccounts = _g[0], setClosedAccounts = _g[1];
    var _h = useState(null), errorMessage = _h[0], setErrorMessage = _h[1];
    var getAccounts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var records, closedRecords, accountsRef, user, query, accountSnapshots, closedQuery, closedAccountSnapshots;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    records = [];
                    closedRecords = [];
                    accountsRef = FirebaseAuth.firestore().collection('accounts');
                    user = FirebaseAuth.auth().currentUser;
                    if (user === null) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    query = accountsRef.where('access', 'array-contains', user.uid);
                    return [4 /*yield*/, query.get()];
                case 1:
                    accountSnapshots = _a.sent();
                    accountSnapshots.forEach(function (account) {
                        records.push({
                            'id': account.id,
                            'name': account.data().name,
                            'subscriptionStatus': account.data().subscriptionStatus,
                            'firebase': account.data().firebase,
                            'features': account.data().features,
                            'stripeActiveSubscriptionID': account.data().stripeActiveSubscriptionID,
                            'subscriptionCurrentPeriodEnd': account.data().subscriptionCurrentPeriodEnd,
                            'type': account.data().type,
                            'plans': [],
                            'owner': account.data().owner,
                            'price': account.data().price,
                            'currency': account.data().currency,
                            'paymentCycle': account.data().paymentCycle
                        });
                    });
                    setAccounts(records);
                    closedQuery = accountsRef
                        .where("accessCount", "==", 0)
                        .where('owner', '==', user.uid);
                    return [4 /*yield*/, closedQuery.get()];
                case 2:
                    closedAccountSnapshots = _a.sent();
                    closedAccountSnapshots.forEach(function (account) {
                        closedRecords.push({
                            'id': account.id,
                            'name': account.data().name,
                            'subscriptionCurrentPeriodEnd': account.data().subscriptionCurrentPeriodEnd
                        });
                    });
                    setClosedAccounts(closedRecords);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var getAllAccounts = function () { return __awaiter(void 0, void 0, void 0, function () {
            var user, accountsRef, times_1, accts_1, inactives_1, frees_1, actives_1, paids_1, allAccountSnapshots, totalAccounts_1, totalActiveAccounts_1, totalInactiveAccounts_1, totalFreeAccounts_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = FirebaseAuth.auth().currentUser;
                        if (!(user !== null && superAdmins.includes(user.uid))) return [3 /*break*/, 2];
                        accountsRef = FirebaseAuth.firestore().collection('accounts');
                        times_1 = [];
                        accts_1 = [];
                        inactives_1 = [];
                        frees_1 = [];
                        actives_1 = [];
                        paids_1 = [];
                        return [4 /*yield*/, accountsRef.orderBy('creationTime', 'asc').get()];
                    case 1:
                        allAccountSnapshots = _a.sent();
                        totalAccounts_1 = 0;
                        totalActiveAccounts_1 = 0;
                        totalInactiveAccounts_1 = 0;
                        totalFreeAccounts_1 = 0;
                        allAccountSnapshots.forEach(function (account) {
                            var _a;
                            var myDate = account.data().creationTime.toDate();
                            var noTime = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
                            times_1.push(noTime.getTime());
                            accts_1.push(++totalAccounts_1);
                            if (account.data().subscriptionStatus === "active") {
                                actives_1.push(++totalActiveAccounts_1);
                            }
                            else {
                                actives_1.push(totalActiveAccounts_1);
                            }
                            if (account.data().subscriptionStatus !== "active") {
                                inactives_1.push(++totalInactiveAccounts_1);
                            }
                            else {
                                inactives_1.push(totalInactiveAccounts_1);
                            }
                            if (account.data().subscriptionStatus === "active" && ((_a = account.data().features) === null || _a === void 0 ? void 0 : _a.trial) === true) {
                                frees_1.push(++totalFreeAccounts_1);
                            }
                            else {
                                frees_1.push(totalFreeAccounts_1);
                            }
                            paids_1.push(totalActiveAccounts_1 - totalFreeAccounts_1);
                        });
                        setStats({
                            times: times_1,
                            accts: accts_1,
                            actives: actives_1,
                            inactives: inactives_1,
                            frees: frees_1,
                            paids: paids_1
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        getAccounts();
        getAllAccounts();
    }, [setBreadcrumb]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsxs("div", { className: "animated fadeIn", children: [!loading && (_jsx("div", { className: "text-right mb-3", children: _jsxs(Link, { to: "/new-account", className: "btn btn-primary", children: [_jsx("i", { className: "fa fa-plus" }), " Add Account"] }) })), ((_a = authUser.user) === null || _a === void 0 ? void 0 : _a.uid) && superAdmins.includes((_b = authUser.user) === null || _b === void 0 ? void 0 : _b.uid) && (_jsxs(_Fragment, { children: [stats !== null ?
                                _jsx(Plot, { data: [
                                        {
                                            x: stats.times,
                                            y: stats.accts,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: { color: '#023E73' },
                                            name: 'All Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.actives,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: { color: '#3FBF04' },
                                            name: 'Active Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.frees,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: { color: '#BF9604' },
                                            name: 'Free Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.paids,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: { color: '#7E04BF' },
                                            name: 'Paid Accounts'
                                        },
                                        {
                                            x: stats.times,
                                            y: stats.inactives,
                                            type: 'scatter',
                                            mode: 'lines',
                                            marker: { color: '#BF0436' },
                                            name: 'Inactive Accounts'
                                        },
                                    ], showlegend: true, legend: {
                                        borderwidth: 0,
                                        x: 1,
                                        xanchor: 'right',
                                        y: 1.1,
                                        orientation: 'h'
                                    }, layout: {
                                        title: 'Daily Account Creations',
                                        images: [{
                                                source: "/logo_large.png",
                                                xref: "paper",
                                                yref: "paper",
                                                x: 0.05,
                                                y: 0.9,
                                                sizex: 0.3,
                                                sizey: 0.3,
                                                opacity: 0.1,
                                                layer: "below"
                                            }],
                                        xaxis: {
                                            autorange: true,
                                            rangeselector: { buttons: [
                                                    {
                                                        count: 1,
                                                        label: '1d',
                                                        step: 'day',
                                                        stepmode: 'backward'
                                                    },
                                                    {
                                                        count: 7,
                                                        label: '7d',
                                                        step: 'day',
                                                        stepmode: 'backward'
                                                    },
                                                    {
                                                        count: 1,
                                                        label: '1m',
                                                        step: 'month',
                                                        stepmode: 'backward'
                                                    },
                                                    {
                                                        count: 1,
                                                        label: '1y',
                                                        step: 'year',
                                                        stepmode: 'backward'
                                                    },
                                                    { step: 'all' }
                                                ] },
                                            // @ts-ignore
                                            rangeslider: { autorange: true },
                                            type: 'date'
                                        },
                                        yaxis: {
                                            autorange: true,
                                            type: 'linear'
                                        },
                                        margin: {
                                            b: 10,
                                            l: 60,
                                            r: 10,
                                            t: 60
                                        }
                                    }, useResizeHandler: true, style: { width: "100%", height: "450px" } })
                                :
                                    _jsx(Placeholder, { animation: "glow", style: { height: 100, width: "100%" } }), _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header font-weight-bold", children: _jsx("h5", { children: "Your Accounts" }) }), errorMessage !== null && (_jsx(AlertPreformatted, { type: "warning", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })), _jsx("div", { className: "card-body", children: _jsx(AdminTable, { accounts: accounts }) })] })] })), ((_c = authUser.user) === null || _c === void 0 ? void 0 : _c.uid) && !superAdmins.includes(authUser.user.uid) ? (_jsx(_Fragment, { children: accounts.length > 3 ?
                            _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header font-weight-bold", children: _jsx("h5", { children: "Your Accounts" }) }), _jsx("div", { className: "card-body", children: _jsx(UserAccountTable, { accounts: accounts, getAccounts: getAccounts }) })] })
                            :
                                _jsx(_Fragment, { children: accounts.map(function (account, i) {
                                        var _a;
                                        return _jsxs("div", { className: "card", children: [_jsxs("div", { className: "card-header font-weight-bold", children: [_jsxs("h6", { style: { float: "right", color: "#aaaaaa" }, children: ["Account ID: ", _jsx("i", { children: account.id })] }), _jsx("h5", { children: _jsx(RenameableHeader, { account: account, getAccounts: getAccounts }) }), account.type === "consumer" && _jsx("h6", { children: "(consumer)" })] }), _jsx("div", { className: "card-body", children: account.subscriptionStatus ? (_jsxs(_Fragment, { children: [((_a = account.features) === null || _a === void 0 ? void 0 : _a.trial) &&
                                                                _jsxs(Button, { style: { float: "right" }, href: '/account/' + account.id + '/billing/plan', variant: "success", children: [_jsx("i", { className: "fas fa-arrow-circle-up" }), " Upgrade"] }), _jsxs(Button, { href: '/account/' + account.id + '/', variant: "primary", children: [_jsx("i", { className: "fa fa-tachometer-alt mr-1" }), " Account Overview"] })] })) : (_jsxs(_Fragment, { children: [_jsxs(Button, { style: { float: "right" }, href: '/account/' + account.id + '/billing/delete', variant: "danger", children: [_jsx("i", { className: "fas fa-trash" }), " Delete"] }), _jsxs(Button, { href: '/account/' + account.id + '/billing/plan', variant: "warning", children: [_jsx("i", { className: "fas fa-check" }), " Activate the account"] })] })) })] }, account.id);
                                    }) }) })) : accounts.length === 0 && (_jsx(_Fragment, { children: (loading) ? (_jsx(Loader, { text: "loading accounts..." })) : (_jsx(Navigate, { to: "/new-account" })) })), closedAccounts.length > 0 && (_jsx(Accordion, { className: "mb-2", children: _jsxs(Accordion.Item, { eventKey: "0", children: [_jsx(Accordion.Header, { children: "Closed Accounts" }), _jsx(Accordion.Body, { children: closedAccounts.map(function (account, i) {
                                        return _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header font-weight-bold", children: _jsx("h5", { children: account.name }) }), _jsxs("div", { className: "card-body", children: [_jsx("div", { className: "alert alert-warning", role: "alert", children: "This account has been closed." }), _jsxs(Button, { href: '/account/' + account.id + '/billing', variant: "primary", children: [_jsx("i", { className: "fa fa-file-invoice-dollar" }), " View Invoices"] })] })] }, account.id);
                                    }) })] }) }))] }) }) }));
};
export default Home;
