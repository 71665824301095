var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { CloudFunctions } from "../FirebaseAuth/firebase";
import { AuthContext } from "../FirebaseAuth";
import Loader from '../Loader';
import CopyToClipboard from "react-copy-to-clipboard";
import Alert from "../Alert";
var OAuthSecrets = function (props) {
    var enabled = props.enabled;
    var _a = React.useState(null), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = React.useState(""), publicKey = _b[0], setPublicKey = _b[1];
    var _c = React.useState(false), inGetPublicKey = _c[0], setGetPublicKey = _c[1];
    var _d = React.useState(false), isCopied = _d[0], setIsCopied = _d[1];
    var userData = React.useContext(AuthContext).userData;
    var onCopyText = function () {
        setIsCopied(true);
        setTimeout(function () {
            setIsCopied(false);
        }, 2000);
    };
    var getLTI11OAuthSecrets = function () { return __awaiter(void 0, void 0, void 0, function () {
        var getSecrets;
        return __generator(this, function (_a) {
            setGetPublicKey(true);
            getSecrets = CloudFunctions.httpsCallable('oncall');
            getSecrets({
                name: "getLTI11OAuthSecrets",
                data: {
                    accountId: userData.currentAccount.id
                }
            }).then(function (res) {
                if (res.data.hasOwnProperty("error")) {
                    setErrorMessage("".concat(res.data.error, ", Error ID:").concat(res.data.details.errorId, " "));
                    setGetPublicKey(false);
                    return;
                }
                if (res.data.hasOwnProperty("error")) {
                    setErrorMessage(res.data.error);
                    setGetPublicKey(false);
                    return;
                }
                setPublicKey("Secret: ".concat(res.data.secret, "\nKey: ").concat(res.data.key));
                setGetPublicKey(false);
            }).catch(function (err) {
                setErrorMessage(err.message);
                setGetPublicKey(false);
            });
            return [2 /*return*/];
        });
    }); };
    return (_jsx(_Fragment, { children: enabled &&
            _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: "LTI 1.1 OAuth Secrets" }), _jsxs("div", { className: "card-body", children: [errorMessage !== null && (_jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })), publicKey !== "" && publicKey !== null &&
                                _jsxs(_Fragment, { children: [_jsx("p", { children: "Use these secrets when registering an LMS for LTI 1.1." }), _jsxs("div", { className: "code-box", children: [_jsx("pre", { className: "code", children: publicKey }), _jsx(CopyToClipboard, { text: publicKey, onCopy: onCopyText, children: _jsxs("div", { className: "copy-area", children: [_jsx("button", { className: "btn btn-info mr-1", children: _jsx("i", { className: "far fa-copy" }) }), isCopied ? (_jsx("span", { style: { color: "var(--danger)" }, children: "Copied." })) : null] }) })] })] }), publicKey === "" && enabled &&
                                _jsxs(_Fragment, { children: [_jsx("p", { children: "LTIAAS does not display your OAuth Secrets after they are initially created. Please click the button below to display your OAuth Secrets." }), _jsx("div", { children: _jsxs("button", { className: "btn mr-2 btn-info", onClick: getLTI11OAuthSecrets, disabled: inGetPublicKey, children: [inGetPublicKey && _jsx(Loader, {}), 'Display OAuth Secrets'] }) })] })] })] }) }));
};
export default OAuthSecrets;
