import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { Link } from "react-router-dom";
var PastDue = function () {
    var userData = useContext(AuthContext).userData;
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false
            },
            {
                to: null,
                text: "Bill Past Due",
                active: true
            }
        ]);
    }, [userData, setBreadcrumb]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: _jsxs("div", { className: "alert alert-danger", role: "alert", children: [_jsx("h4", { className: "alert-heading", children: "Bill Past Due" }), _jsx("hr", {}), _jsxs("p", { children: ["Your bill has not been paid and is past due. Your service is still running but your account is locked. To view your most recent invoice, please go to the ", _jsx("b", { children: _jsx(Link, { to: "/account/".concat(userData.currentAccount.id, "/billing"), children: "Billing Page" }) })] }), _jsxs("p", { className: "mb-0", children: ["If your bill is not paid within 30 days of the due date, your account may be shut down. Please contact ", _jsx("i", { children: _jsx("a", { target: "blank", href: "mailto:support@ltiaas.com", children: "support@ltiaas.com" }) }), " if you need help resolving this issue."] })] }) }) }) }));
};
export default PastDue;
