var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { Button, Table } from "react-bootstrap";
import { Input } from "../../../components/Form";
import AlertPreformatted from "../../../components/AlertPreformatted";
import { CloudFunctions } from "../../../components/FirebaseAuth/firebase";
export var AdminTable = function (_a) {
    var accounts = _a.accounts;
    var _b = useState({
        hasError: false,
        error: null,
        value: "",
    }), searchName = _b[0], setSearchName = _b[1];
    var _c = useState({
        hasError: false,
        error: null,
        value: "",
    }), searchId = _c[0], setSearchId = _c[1];
    var _d = useState(false), sort = _d[0], setSort = _d[1];
    var _e = useState(false), sortFirebase = _e[0], setSortFirebase = _e[1];
    var _f = useState(false), gettingStats = _f[0], setGettingStats = _f[1];
    var sortedAccounts = accounts.sort(function (a, b) {
        var statusA = a.subscriptionStatus !== undefined;
        var statusB = b.subscriptionStatus !== undefined;
        if (statusA && !statusB) {
            return sort ? 1 : -1;
        }
        if (!statusA && statusB) {
            return sort ? -1 : 1;
        }
        return 0;
    });
    var firebaseSortedAccounts = sortedAccounts.sort(function (a, b) {
        var statusA = a.firebase;
        var statusB = b.firebase;
        if (statusA && !statusB) {
            return sortFirebase ? -1 : 1;
        }
        if (!statusA && statusB) {
            return sortFirebase ? 1 : -1;
        }
        return 0;
    });
    var filteredAccounts = firebaseSortedAccounts.filter(function (item) {
        var _a, _b;
        if (searchName.value.length > 1) {
            return item.name.toLowerCase().includes((_a = searchName.value) === null || _a === void 0 ? void 0 : _a.toLowerCase());
        }
        else if (searchId.value.length > 1) {
            return item.id.toLowerCase().includes((_b = searchId.value) === null || _b === void 0 ? void 0 : _b.toLowerCase());
        }
        else {
            return true;
        }
    });
    var _g = useState(null), errorMessage = _g[0], setErrorMessage = _g[1];
    var getStats = function (id, subscriptionCurrentPeriodEnd) { return __awaiter(void 0, void 0, void 0, function () {
        var onCall, mets, regs, dateEnd, dateToday, DifferenceInTime, DifferenceInDays, data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setGettingStats(true);
                    onCall = CloudFunctions.httpsCallable('oncall');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, onCall({ name: "getApiMetrics", data: { accountId: id } })];
                case 2:
                    mets = _a.sent();
                    return [4 /*yield*/, onCall({ name: "getRegistrations", data: { accountId: id } })];
                case 3:
                    regs = _a.sent();
                    dateEnd = new Date(0);
                    dateEnd.setUTCSeconds(subscriptionCurrentPeriodEnd);
                    dateToday = new Date();
                    DifferenceInTime = dateEnd.getTime() - dateToday.getTime();
                    DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
                    data = { BillingEndsIn: DifferenceInDays.toFixed(2) + " days", Registrations: regs.data.hasOwnProperty('platforms') ? regs.data.platforms.length : regs.data.length, Metrics: mets.data[0] };
                    setErrorMessage(JSON.stringify(data, null, 2));
                    setGettingStats(false);
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    setErrorMessage("ERROR: " + e_1);
                    setGettingStats(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var getDomain = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var subdomainRef, subdomain, sorted, accountRef, account, found, i, plan;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, FirebaseAuth.firestore().collection('subdomains').doc(id).get()];
                case 1:
                    subdomainRef = _a.sent();
                    if (!subdomainRef)
                        return [2 /*return*/, null];
                    subdomain = subdomainRef.data();
                    if (!(subdomain !== undefined)) return [3 /*break*/, 2];
                    sorted = Object.keys(subdomain)
                        .sort()
                        .reduce(function (accumulator, key) {
                        accumulator[key] = subdomain[key];
                        return accumulator;
                    }, {});
                    setErrorMessage(JSON.stringify(sorted, null, 2));
                    return [3 /*break*/, 9];
                case 2: return [4 /*yield*/, FirebaseAuth.firestore().collection('accounts').doc(id).get()];
                case 3:
                    accountRef = _a.sent();
                    if (!!accountRef) return [3 /*break*/, 4];
                    setErrorMessage("This account has not been deployed yet");
                    return [3 /*break*/, 9];
                case 4:
                    account = accountRef.data();
                    found = false;
                    i = 0;
                    _a.label = 5;
                case 5:
                    if (!(i < account.plans.length)) return [3 /*break*/, 8];
                    return [4 /*yield*/, account.plans[i].get()];
                case 6:
                    plan = _a.sent();
                    // @ts-ignore
                    if (plan.data().name.includes("Custom Domain")) {
                        found = true;
                    }
                    _a.label = 7;
                case 7:
                    i++;
                    return [3 /*break*/, 5];
                case 8:
                    if (found) {
                        setErrorMessage("WARNING: This account as a Custom Domain, but has not been deployed yet");
                    }
                    else {
                        setErrorMessage("This account has not been deployed yet");
                    }
                    _a.label = 9;
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var switchToFirebase = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var subdomainRef, subdomain, accountRef, accountRefGet, account, found, i, plan;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, FirebaseAuth.firestore().collection('subdomains').doc(id).get()];
                case 1:
                    subdomainRef = _a.sent();
                    if (!subdomainRef)
                        return [2 /*return*/, null];
                    subdomain = subdomainRef.data();
                    if (!(subdomain !== undefined)) return [3 /*break*/, 2];
                    setErrorMessage("This account has already been activated, it needs to be migrated manually.");
                    return [3 /*break*/, 10];
                case 2:
                    accountRef = FirebaseAuth.firestore().collection("accounts").doc(id);
                    return [4 /*yield*/, accountRef.get()];
                case 3:
                    accountRefGet = _a.sent();
                    account = accountRefGet.data();
                    found = false;
                    if (!account.plans) return [3 /*break*/, 7];
                    i = 0;
                    _a.label = 4;
                case 4:
                    if (!(i < account.plans.length)) return [3 /*break*/, 7];
                    return [4 /*yield*/, account.plans[i].get()];
                case 5:
                    plan = _a.sent();
                    // @ts-ignore
                    if (plan.data().name.includes("Custom Domain")) {
                        found = true;
                    }
                    _a.label = 6;
                case 6:
                    i++;
                    return [3 /*break*/, 4];
                case 7:
                    if (!found) return [3 /*break*/, 8];
                    setErrorMessage("WARNING: This account as a Custom Domain, but has not been deployed yet.\nIt was NOT moved to firebase.");
                    return [3 /*break*/, 10];
                case 8: return [4 /*yield*/, accountRef.set({
                        firebase: true,
                        apiKey: ""
                    }, { merge: true })];
                case 9:
                    _a.sent();
                    setErrorMessage("Account is now on firebase");
                    _a.label = 10;
                case 10: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [errorMessage !== null && (_jsx(AlertPreformatted, { type: "warning", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })), _jsxs(Table, { striped: true, bordered: true, hover: true, size: "sm", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Account Name" }), _jsx("th", { children: "Account Id" }), _jsx("th", { children: "Stripe Id" }), _jsx("th", { children: "Firebase" }), _jsx("th", { children: "Actions" })] }) }), _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { children: _jsx(Input, { type: "text", value: searchName.value, placeholder: "Search...", name: "searchName", required: false, changeHandler: setSearchName }) }), _jsx("td", { children: _jsx(Input, { type: "text", value: searchId.value, placeholder: "Search...", name: "searchId", required: false, changeHandler: setSearchId }) }), _jsx("td", {}), _jsx("td", { children: _jsx(Button, { onClick: function () { return setSortFirebase(!sortFirebase); }, children: "Sort" }) }), _jsx("td", { children: _jsx(Button, { onClick: function () { return setSort(!sort); }, children: "Sort" }) })] }), filteredAccounts.map(function (account, i) {
                                var _a;
                                return _jsxs("tr", { children: [_jsxs("td", { children: [account.name, account.type === "consumer" && (_jsx("i", { children: " (consumer)" })), ((_a = account.features) === null || _a === void 0 ? void 0 : _a.trial) && (_jsx("i", { children: " (trial)" }))] }), _jsx("td", { children: account.id }), _jsx("td", { children: account.stripeActiveSubscriptionID }), _jsx("td", { children: account.firebase ? "true" : "false" }), _jsxs("td", { children: [account.subscriptionStatus ? (_jsxs(_Fragment, { children: [_jsx(Button, { href: '/account/' + account.id + '/', variant: "primary", children: "Account Overview" }), _jsx(Button, { variant: "info", onClick: function () { return getDomain(account.id); }, children: "Domain?" }), _jsx(Button, { variant: "success", disabled: gettingStats, onClick: function () { return getStats(account.id, account.subscriptionCurrentPeriodEnd); }, children: "Stats?" })] })) : (_jsx(_Fragment, { children: _jsx(Button, { href: '/account/' + account.id + '/billing/plan', variant: "warning", children: "Activate the account" }) })), false && !account.firebase &&
                                                    _jsx(Button, { variant: "danger", onClick: function () { return switchToFirebase(account.id); }, children: "Switch To Firebase" })] })] }, account.id);
                            })] })] })] }));
};
