import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
var StyledFirebaseAuth = function (_a) {
    var uiConfig = _a.uiConfig, firebaseAuth = _a.firebaseAuth, className = _a.className, uiCallback = _a.uiCallback;
    var _b = useState(false), userSignedIn = _b[0], setUserSignedIn = _b[1];
    var elementRef = useRef(null);
    useEffect(function () {
        // Get or Create a firebaseUI instance.
        var firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
        if (uiConfig.signInFlow === 'popup')
            firebaseUiWidget.reset();
        // We track the auth state to reset firebaseUi if the user signs out.
        var unregisterAuthObserver = onAuthStateChanged(firebaseAuth, function (user) {
            if (!user && userSignedIn)
                firebaseUiWidget.reset();
            setUserSignedIn(!!user);
        });
        // Trigger the callback if any was set.
        if (uiCallback)
            uiCallback(firebaseUiWidget);
        // Render the firebaseUi Widget.
        // @ts-ignore
        firebaseUiWidget.start(elementRef.current, uiConfig);
        return function () {
            unregisterAuthObserver();
            firebaseUiWidget.reset();
        };
    }, [firebaseui, uiConfig]);
    return _jsx("div", { className: className, ref: elementRef });
};
export default StyledFirebaseAuth;
