import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import Loader from "../../../../components/Loader";
import Alert from "../../../../components/Alert";
import { Link } from "react-router-dom";
var Invite = function () {
    var code = useParams().code;
    var title = 'View Invite';
    var _a = useState(null), invite = _a[0], setInvite = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useState(false), inSubmit = _c[0], setInSubmit = _c[1];
    var _d = useState(false), success = _d[0], setSuccess = _d[1];
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        if (code) {
            var isSubscribed_1 = true;
            setError(null);
            var getInvite = CloudFunctions.httpsCallable('oncall');
            getInvite({
                name: "getInvite",
                data: {
                    inviteId: code
                }
            }).then(function (res) {
                if (isSubscribed_1) {
                    setInvite(res.data);
                }
            }).catch(function (err) {
                if (isSubscribed_1) {
                    setError(err.message);
                }
            });
        }
        else {
            setError("Invite code not found");
        }
    }, [code, setBreadcrumb, title]);
    return (_jsx(_Fragment, { children: (success && (invite !== null)) ? (_jsx(Navigate, { to: "/account/" + invite.accountId + "/" })) : (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), _jsxs("div", { className: "card-body", children: [error !== null &&
                                        _jsx(Alert, { type: "danger", message: error, dismissible: true, onDismiss: function () { return setError(null); } }), invite === null ? (_jsx(Loader, { text: "Loading the invite..." })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "text-center", children: ["This invite will grant you access to ", _jsx("strong", { children: invite.accountName }), ". Do you want to accept it?"] }), _jsxs("div", { className: "text-center mt-3", children: [_jsxs("button", { className: "btn btn-primary mr-2", disabled: inSubmit, onClick: function (e) {
                                                            e.preventDefault();
                                                            setInSubmit(true);
                                                            var acceptInvite = CloudFunctions.httpsCallable('oncall');
                                                            acceptInvite({
                                                                name: "acceptInvite",
                                                                data: {
                                                                    inviteId: code
                                                                }
                                                            }).then(function (res) {
                                                                setSuccess(true);
                                                            }).catch(function (err) {
                                                                setError(err.message);
                                                            });
                                                        }, children: [inSubmit && _jsx(Loader, {}), "Yes, accept the invite"] }), _jsx(Link, { className: inSubmit ? ("btn btn-secondary ml-2 btn-disabled") : ("btn btn-secondary ml-2"), to: "/", onClick: function (e) {
                                                            if (inSubmit) {
                                                                e.preventDefault();
                                                            }
                                                        }, children: "Ignore" })] })] }))] })] }) }) }) })) }));
};
export default Invite;
