import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { Form, Field } from '../../../../components/Form';
import { AuthContext } from '../../../../components/FirebaseAuth';
import Alert from '../../../../components/Alert';
import UserPageLayout from '../../../../components/user/UserPageLayout';
var UpdateEmail = function () {
    var _a, _b, _c;
    var title = "Verify Your Email";
    var authUser = useContext(AuthContext).authUser;
    var _d = useState({
        status: null,
        message: ''
    }), result = _d[0], setResult = _d[1];
    var _e = useState(false), inSubmit = _e[0], setInSubmit = _e[1];
    return (_jsxs(UserPageLayout, { title: title, children: [result.status === null && !((_a = authUser.user) === null || _a === void 0 ? void 0 : _a.emailVerified) &&
                _jsx(Form, { handleSubmit: function (e) {
                        var _a;
                        e.preventDefault();
                        setInSubmit(true);
                        (_a = authUser.user) === null || _a === void 0 ? void 0 : _a.sendEmailVerification().then(function () {
                            setResult({
                                status: true,
                                message: 'Please check your email inbox to verify the email address. Refresh this page after you verified your email address.'
                            });
                            setInSubmit(false);
                        }).catch(function (err) {
                            setResult({
                                status: false,
                                message: err.message
                            });
                            setInSubmit(false);
                        });
                    }, disabled: inSubmit, inSubmit: inSubmit, enableDefaultButtons: true, backToUrl: "/user/profile", children: _jsx(Field, { label: "Email Address", children: _jsx("input", { type: "text", readOnly: true, className: "form-control-plaintext", value: ((_b = authUser.user) === null || _b === void 0 ? void 0 : _b.email) || "" }) }) }), result.status === null && ((_c = authUser.user) === null || _c === void 0 ? void 0 : _c.emailVerified) &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "success", dismissible: false, message: "Your email is already verified.", onDismiss: function () { } }), _jsx(Link, { className: "btn btn-primary", to: "/user/profile", children: "View Profile" })] }), result.status === false &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "danger", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx("button", { className: "btn btn-primary mr-2", onClick: function () {
                                setResult({
                                    status: null,
                                    message: ''
                                });
                            }, children: "Try Again" }), _jsx(Link, { className: "btn btn-secondary", to: "/user/profile", children: "View Profile" })] }), result.status === true &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "success", dismissible: false, message: result.message, onDismiss: function () { } }), _jsx(Link, { className: "btn btn-primary", to: "/user/profile", children: "View Profile" })] })] }));
};
export default UpdateEmail;
