var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import PropTypes from "prop-types";
import Field from './Field';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import { Button } from "react-bootstrap";
var Form = function (props) {
    var handleSubmit = props.handleSubmit, disabled = props.disabled, inSubmit = props.inSubmit, enableDefaultButtons = props.enableDefaultButtons, submitBtnStyle = props.submitBtnStyle, submitBtnText = props.submitBtnText, backBtnText = props.backBtnText, backToUrl = props.backToUrl, children = props.children, others = __rest(props, ["handleSubmit", "disabled", "inSubmit", "enableDefaultButtons", "submitBtnStyle", "submitBtnText", "backBtnText", "backToUrl", "children"]);
    var btnClass = "primary";
    if (submitBtnStyle) {
        btnClass = submitBtnStyle;
    }
    return (_jsxs("form", __assign({}, others, { onSubmit: handleSubmit, children: [children, enableDefaultButtons &&
                _jsxs(Field, { children: [_jsxs(Button, { type: "submit", variant: "success", size: "lg", className: "btn mr-2 btn-" + btnClass, disabled: disabled || inSubmit, children: [inSubmit &&
                                    _jsx(Loader, {}), ((disabled && !inSubmit) ? (_jsx("i", { className: "fa fa-ban mr-1" })) : ''), submitBtnText || 'Submit'] }), backToUrl && backToUrl !== "" &&
                            _jsx(Link, { className: "btn btn-secondary" + (inSubmit ? " disabled" : ""), to: backToUrl, children: backBtnText || 'Back' })] })] })));
};
Form.propTypes = {
    handleSubmit: PropTypes.func,
    disabled: PropTypes.bool,
    inSubmit: PropTypes.bool,
    enableDefaultButtons: PropTypes.bool,
    submitBtnText: PropTypes.string,
    backBtnText: PropTypes.string,
    backToUrl: PropTypes.string
};
export default Form;
