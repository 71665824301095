var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from "react";
import Alert from "../../../../components/Alert";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { Field, Form, Input } from "../../../../components/Form";
import Modal from 'react-bootstrap/Modal';
import firebase from "firebase/compat/app";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Buffer } from 'buffer';
var Secrets = function () {
    var _a = useState({
        hasError: false,
        error: null,
        value: ""
    }), secretId = _a[0], setSecretId = _a[1];
    var _b = useState({
        hasError: false,
        error: null,
        value: ""
    }), message = _b[0], setMessage = _b[1];
    var _c = useState({
        hasError: false,
        error: null,
        value: ""
    }), decryptionKey = _c[0], setDecryptionKey = _c[1];
    var _d = useState(false), secretFormVisible = _d[0], setSecretFormVisible = _d[1];
    var _e = useState(false), decryptionKeyVisible = _e[0], setDecryptionKeyVisible = _e[1];
    var _f = useState(false), decryptedMessageVisible = _f[0], setDecryptedMessageVisible = _f[1];
    var _g = useState(false), inSubmit = _g[0], setInSubmit = _g[1];
    var _h = useState(null), encryptedDocId = _h[0], setEncryptedDocId = _h[1];
    var _j = useState(null), encryptionKey = _j[0], setEncryptionKey = _j[1];
    var _k = useState(null), decryptedMessage = _k[0], setDecryptedMessage = _k[1];
    var _l = useState(false), isEncryptedDocIdCopied = _l[0], setIsEncryptedDocIdCopied = _l[1];
    var _m = useState(false), isEncryptionKeyCopied = _m[0], setIsEncryptionKeyCopied = _m[1];
    var _o = useState(false), isDecryptedMessageCopied = _o[0], setIsDecryptedMessageCopied = _o[1];
    var _p = useState(null), errorMessage = _p[0], setErrorMessage = _p[1];
    var onCopyEncryptedDocId = function () {
        setIsEncryptedDocIdCopied(true);
        setTimeout(function () {
            setIsEncryptedDocIdCopied(false);
        }, 2000);
    };
    var onCopyEncryptionKey = function () {
        setIsEncryptionKeyCopied(true);
        setTimeout(function () {
            setIsEncryptionKeyCopied(false);
        }, 2000);
    };
    var onCopyDecryptedMessage = function () {
        setIsDecryptedMessageCopied(true);
        setTimeout(function () {
            setIsDecryptedMessageCopied(false);
        }, 2000);
    };
    var createSecret = function () { return __awaiter(void 0, void 0, void 0, function () {
        var key, enc, encoded_message, iv, encrypted, createdAt, exp, iv_arr, base64_encrypted, data, docRef, exported_key, exportedKeyBuffer, base64_key;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setInSubmit(true);
                    return [4 /*yield*/, window.crypto.subtle.generateKey({
                            name: "AES-GCM",
                            length: 256
                        }, true, ["encrypt", "decrypt"])];
                case 1:
                    key = _a.sent();
                    enc = new TextEncoder();
                    encoded_message = enc.encode(message.value);
                    return [4 /*yield*/, window.crypto.getRandomValues(new Uint8Array(12))];
                case 2:
                    iv = _a.sent();
                    return [4 /*yield*/, window.crypto.subtle.encrypt({ name: "AES-GCM", iv: iv }, key, encoded_message)];
                case 3:
                    encrypted = _a.sent();
                    createdAt = firebase.firestore.Timestamp.now().toDate();
                    createdAt.setDate(createdAt.getDate() + 7);
                    exp = firebase.firestore.Timestamp.fromDate(createdAt);
                    iv_arr = [];
                    iv.forEach(function (el) {
                        iv_arr.push(el.valueOf());
                    });
                    base64_encrypted = Buffer.from(encrypted).toString('base64');
                    data = {
                        data: base64_encrypted,
                        iv: iv_arr,
                        expires: exp
                    };
                    docRef = FirebaseAuth.firestore().collection('secrets').doc();
                    return [4 /*yield*/, docRef.set(data)];
                case 4:
                    _a.sent();
                    setEncryptedDocId(docRef.id);
                    return [4 /*yield*/, window.crypto.subtle.exportKey("raw", key)];
                case 5:
                    exported_key = _a.sent();
                    exportedKeyBuffer = new Uint8Array(exported_key);
                    base64_key = Buffer.from(exportedKeyBuffer).toString('base64');
                    setEncryptionKey(base64_key);
                    setMessage({ value: "", hasError: false, error: null });
                    setInSubmit(false);
                    setSecretFormVisible(false);
                    setDecryptionKeyVisible(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var getSecret = function () { return __awaiter(void 0, void 0, void 0, function () {
        var docRef, secretDoc, doc_data, ciphertext, encodedBuffer, rawKey, key, ivBuffer, iv, i, message_1, dec, decoded_message, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setInSubmit(true);
                    docRef = FirebaseAuth.firestore().collection('secrets').doc(secretId.value);
                    return [4 /*yield*/, docRef.get()];
                case 1:
                    secretDoc = _a.sent();
                    doc_data = secretDoc.data();
                    if (!(doc_data && doc_data.hasOwnProperty("data") && doc_data.hasOwnProperty("iv"))) return [3 /*break*/, 8];
                    ciphertext = doc_data.data;
                    encodedBuffer = Buffer.from(ciphertext, 'base64');
                    rawKey = Buffer.from(decryptionKey.value, 'base64');
                    return [4 /*yield*/, window.crypto.subtle.importKey("raw", rawKey, "AES-GCM", true, ["encrypt", "decrypt"])];
                case 2:
                    key = _a.sent();
                    ivBuffer = new Uint8Array(12);
                    iv = doc_data.iv;
                    for (i = 0; i < 12; i++) {
                        ivBuffer[i] = iv[i];
                    }
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, window.crypto.subtle.decrypt({ name: "AES-GCM", iv: ivBuffer }, key, encodedBuffer)];
                case 4:
                    message_1 = _a.sent();
                    dec = new TextDecoder();
                    decoded_message = dec.decode(message_1);
                    setDecryptedMessage(decoded_message.toString());
                    setDecryptedMessageVisible(true);
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    setErrorMessage("Message Decryption Failed");
                    return [3 /*break*/, 6];
                case 6: return [4 /*yield*/, docRef.delete()];
                case 7:
                    _a.sent();
                    return [3 /*break*/, 9];
                case 8:
                    setErrorMessage("Message Not Found");
                    _a.label = 9;
                case 9:
                    setInSubmit(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var title = 'Secret Sharing';
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsxs("div", { className: "animated fadeIn", children: [_jsx("div", { className: "text-right mb-3", children: _jsxs("button", { className: "btn btn-primary", onClick: function () { return setSecretFormVisible(true); }, children: [_jsx("i", { className: "fa fa-plus" }), " Create Secret"] }) }), _jsxs(Modal, { show: secretFormVisible, onHide: function () {
                            setSecretFormVisible(false);
                        }, backdrop: "static", keyboard: false, dialogClassName: "modal-90w", children: [_jsx(Modal.Header, { closeButton: true, children: _jsx(Modal.Title, { children: "New Secret" }) }), _jsxs(Modal.Body, { children: [_jsx(Alert, { type: "info", message: "Enter the information you want to encrypt in the box below. LTIAAS will encrypt it and give you a decryption key and an ID that can be used to retrieve the data only once.", dismissible: false, onDismiss: function () { } }), _jsx(Form, { handleSubmit: function (e) {
                                            e.preventDefault();
                                            createSecret();
                                        }, disabled: message.hasError || message.value === "" || inSubmit, submitBtnStyle: "primary", inSubmit: inSubmit, enableDefaultButtons: true, children: _jsx(Field, { label: "Message", children: _jsx(Input, { type: "textarea", rows: "10", name: "message", maxLen: 10000, hasError: message.hasError, error: message.error, required: true, changeHandler: setMessage }) }) })] })] }), _jsxs(Modal, { show: decryptionKeyVisible, onHide: function () {
                            setDecryptionKeyVisible(false);
                        }, backdrop: "static", keyboard: false, dialogClassName: "modal-90w", children: [_jsx(Modal.Header, { closeButton: true, children: _jsx(Modal.Title, { children: "Message Encrypted" }) }), _jsxs(Modal.Body, { children: [_jsx(Alert, { type: "info", message: "Your message is stored encrypted on LTIAAS servers for 7 days. Once it is read it will be deleted. Copy the Secret ID and Decryption Key below and send them to the person that you want to read the message you just encrypted.", dismissible: false, onDismiss: function () { } }), _jsx("b", { children: "Secret ID:" }), " ", _jsx("code", { children: encryptedDocId }), _jsx(CopyToClipboard, { text: encryptedDocId || "", onCopy: onCopyEncryptedDocId, children: _jsxs("div", { className: "copy-area", children: [_jsx("button", { className: "btn btn-info mr-1", children: _jsx("i", { className: "far fa-copy" }) }), isEncryptedDocIdCopied ? (_jsx("span", { style: { color: "var(--danger)" }, children: "Copied." })) : null] }) }), _jsx("br", {}), _jsx("br", {}), _jsx("b", { children: "Decryption Key:" }), " ", _jsx("code", { children: encryptionKey }), _jsx(CopyToClipboard, { text: encryptionKey || "", onCopy: onCopyEncryptionKey, children: _jsxs("div", { className: "copy-area", children: [_jsx("button", { className: "btn btn-info mr-1", children: _jsx("i", { className: "far fa-copy" }) }), isEncryptionKeyCopied ? (_jsx("span", { style: { color: "var(--danger)" }, children: "Copied." })) : null] }) })] })] }), _jsxs(Modal, { show: decryptedMessageVisible, onHide: function () {
                            setDecryptedMessageVisible(false);
                            setDecryptedMessage(null);
                        }, backdrop: "static", keyboard: false, dialogClassName: "modal-90w", children: [_jsx(Modal.Header, { closeButton: true, children: _jsx(Modal.Title, { children: "Decrypted Message" }) }), _jsxs(Modal.Body, { children: [_jsx(Alert, { type: "info", message: "Here is your one-time message. It has been deleted from our servers.", dismissible: false, onDismiss: function () { } }), _jsxs("div", { className: "code-box", children: [_jsx("pre", { className: "code", children: decryptedMessage }), _jsx(CopyToClipboard, { text: decryptedMessage || "", onCopy: onCopyDecryptedMessage, children: _jsxs("div", { className: "copy-area", children: [_jsx("button", { className: "btn btn-info mr-1", children: _jsx("i", { className: "far fa-copy" }) }), isDecryptedMessageCopied ? (_jsx("span", { style: { color: "var(--danger)" }, children: "Copied." })) : null] }) })] })] })] }), _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), _jsxs("div", { className: "card-body", children: [_jsx(Alert, { type: "info", message: "Occasionally, it may become necessary for LTIAAS to share secrets with you such as API Keys or Private/Public Key pairs. This page allows you to create and retrieve encrypted self-destructing secret messages.", dismissible: false, onDismiss: function () { } }), _jsxs(Form, { handleSubmit: function (e) {
                                            e.preventDefault();
                                            getSecret();
                                        }, disabled: decryptionKey.hasError || decryptionKey.value === "" || secretId.hasError || secretId.value === "" || inSubmit, submitBtnStyle: "primary", inSubmit: inSubmit, enableDefaultButtons: true, children: [_jsx(Field, { label: "Secret ID", children: _jsx(Input, { type: "text", name: "secret-id", placeholder: "Secret ID", hasError: secretId.hasError, error: secretId.error, minLen: 5, maxLen: 500, required: true, changeHandler: setSecretId }) }), _jsx(Field, { label: "Decryption Key", children: _jsx(Input, { type: "text", name: "decryption-key", placeholder: "Decryption Key", hasError: decryptionKey.hasError, error: decryptionKey.error, minLen: 5, maxLen: 500, required: true, changeHandler: setDecryptionKey }) }), errorMessage !== null && _jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })] })] })] })] }) }) }));
};
export default Secrets;
