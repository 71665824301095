import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import SidebarLink from '../SidebarLink';
var AppMenu = function () {
    useEffect(function () {
        document.querySelectorAll('.c-sidebar').forEach(function (element) {
            //@ts-ignore
            window.coreui.Sidebar._sidebarInterface(element);
        });
    });
    return (_jsxs("ul", { className: "c-sidebar-nav ps ps--active-y", children: [_jsx("li", { className: "c-sidebar-nav-title", children: "Application" }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname === '/' ? " active" : ""), to: "/", children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-th-large" }), "My Accounts"] }) }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname === '/secrets' ? " active" : ""), to: "/secrets", children: [_jsx("i", { className: "c-sidebar-nav-icon fas fa-key" }), "Secret Sharing"] }) }), _jsx("li", { className: "c-sidebar-nav-title", children: "User" }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname.startsWith('/user/profile') ? " active" : ""), to: "/user/profile", children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-user" }), " Profile"] }) }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname.startsWith('/user/log') ? " active" : ""), to: "/user/log", children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-list" }), " Activity Logs"] }) })] }));
};
export default AppMenu;
