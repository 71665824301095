var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import Highlight from 'react-highlight';
import { Link } from "react-router-dom";
import PublicTemplate from "../../../../components/templates/PublicTemplate";
import Loader from "../../../../components/Loader";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
var Logs = function () {
    var title = "Logs";
    var userData = useContext(AuthContext).userData;
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    var _a = useState(null), settingsComplete = _a[0], setSettingsComplete = _a[1];
    var _b = useState(null), logs = _b[0], setLogs = _b[1];
    var _c = useState(null), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var fetchLogs = function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
        var doc, api_settings, getSubdomainLogs, res, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSettingsComplete(false);
                    return [4 /*yield*/, FirebaseAuth.firestore().collection("subdomains").doc(accountId).get()];
                case 1:
                    doc = _a.sent();
                    if (!doc.exists) return [3 /*break*/, 8];
                    api_settings = doc.data();
                    if (!(api_settings !== undefined && api_settings.hasOwnProperty("subdomainName"))) return [3 /*break*/, 6];
                    getSubdomainLogs = CloudFunctions.httpsCallable('oncall');
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, getSubdomainLogs({
                            name: "getSubdomainLogs",
                            data: {
                                accountId: userData.currentAccount.id
                            }
                        })];
                case 3:
                    res = _a.sent();
                    setLogs(res.data);
                    setSettingsComplete(true);
                    setLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    setErrorMessage(err_1.message || "Something went wrong");
                    setLogs(null);
                    setSettingsComplete(true);
                    setLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [3 /*break*/, 7];
                case 6:
                    setLogs("");
                    setSettingsComplete(false);
                    setLoading(false);
                    _a.label = 7;
                case 7: return [3 /*break*/, 9];
                case 8:
                    setLogs("");
                    setSettingsComplete(false);
                    setLoading(false);
                    _a.label = 9;
                case 9: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchLogs(userData.currentAccount.id);
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false,
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false,
            },
            {
                to: null,
                text: title,
                active: true,
            },
        ]);
    }, [userData, setBreadcrumb, title]);
    return (_jsx(_Fragment, { children: loading ? (_jsx(PublicTemplate, { children: _jsx(Loader, { size: "5x", text: "Loading..." }) })) : (_jsx(_Fragment, { children: _jsxs("div", { className: "container-fluid", children: [_jsxs("div", { className: "animated fadeIn", children: [errorMessage !== null && (_jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })), !settingsComplete && logs !== null && (_jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: "Finish your API setup" }), _jsx("div", { className: "card-body", children: _jsxs("p", { children: ["Before you API can go live, you need to set your API settings. Please edit your \u00A0", _jsx("b", { children: _jsx(Link, { to: "/account/" + userData.currentAccount.id + "/settings", children: "API Settings" }) }), "."] }) })] })), _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), _jsxs("div", { className: "card-body", children: [!userData.currentAccount.subscriptionStatus && (_jsxs("p", { children: ["Account status is not active,", " ", _jsx(Link, { to: "/account/" + userData.currentAccount.id + "/plan", children: "activate a plan here to continue" }), "."] })), logs &&
                                                _jsx(Highlight, { className: 'language-accesslog', children: logs }), logs === null && _jsx("p", { children: "Fetching logs from server..." })] })] })] }), _jsx(Alert, { type: "info", message: "We only store LTI-related logs for the last 100 entries. These logs should be used for debugging and troubleshooting purposes only. Other access logs are stored on our servers for 30 days. If you need access to these logs, please contact us at support@ltiaas.com." })] }) })) }));
};
export default Logs;
