import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../components/Loader";
import UserAvatar from '../../../../components/UserAvatar';
import Alert from "../../../../components/Alert";
import { Form, Field } from "../../../../components/Form";
var UserRole = function () {
    var title = 'Change User Role';
    var userData = useContext(AuthContext).userData;
    var userId = useParams().userId;
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    var _a = useState(null), user = _a[0], setUser = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useState(false), success = _c[0], setSuccess = _c[1];
    var _d = useState(false), inSubmit = _d[0], setInSubmit = _d[1];
    var _e = useState(null), selectedRole = _e[0], setSelectedRole = _e[1];
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/users",
                text: 'Manage Users',
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        setError(null);
        var getAccountUser = CloudFunctions.httpsCallable('oncall');
        getAccountUser({
            name: "getAccountUser",
            data: {
                accountId: userData.currentAccount.id,
                userId: userId
            }
        }).then(function (res) {
            res.data.lastLoginTime = new Date(res.data.lastLoginTime);
            setUser(res.data);
        }).catch(function (err) {
            setError(err.message);
        });
    }, [userData, userId, setBreadcrumb]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), _jsx("div", { className: "card-body", children: (userId !== userData.currentAccount.owner) ? (_jsxs(_Fragment, { children: [success &&
                                        _jsx(Alert, { type: "success", message: "User role is successfully updated.", dismissible: true, onDismiss: function () { return setSuccess(false); } }), error !== null &&
                                        _jsx(Alert, { type: "danger", message: error, dismissible: true, onDismiss: function () { return setError(null); } }), user === null ? (_jsx(Loader, { text: "Loading user details" })) : (_jsxs(Form, { handleSubmit: function (e) {
                                            e.preventDefault();
                                            setError(null);
                                            setSuccess(false);
                                            setInSubmit(true);
                                            var updateAccountUserRole = CloudFunctions.httpsCallable('oncall');
                                            updateAccountUserRole({
                                                name: "updateAccountUserRole",
                                                data: {
                                                    accountId: userData.currentAccount.id,
                                                    userId: userId,
                                                    role: selectedRole
                                                }
                                            }).then(function (res) {
                                                setInSubmit(false);
                                                setSuccess(true);
                                            }).catch(function (err) {
                                                setInSubmit(false);
                                                setError(err.message);
                                            });
                                        }, disabled: selectedRole === null || inSubmit, submitBtnStyle: (selectedRole !== 'remove') ? "primary" : "danger", inSubmit: inSubmit, enableDefaultButtons: true, backToUrl: "/account/" + userData.currentAccount.id + "/users", children: [_jsx(Field, { label: "User Name", children: _jsxs("div", { className: "row col", children: [_jsx(UserAvatar, { name: user.displayName, photoUrl: user.photoUrl, className: "c-avatar-img mr-2" }), _jsx("div", { className: "pt-2 ml-1", children: user.displayName })] }) }), _jsx(Field, { label: "Last Login Time", children: _jsx("div", { className: "my-1", children: user.lastLoginTime.toLocaleString() }) }), _jsx(Field, { label: "Role", children: _jsxs("select", { className: "form-control col-md-6 col-sm-8", defaultValue: user.role, onChange: function (e) {
                                                        setSelectedRole(e.target.value);
                                                    }, children: [_jsx("option", { value: "user", children: "user" }), _jsx("option", { value: "registrar", children: "registrar" }), _jsx("option", { value: "admin", children: "admin" }), _jsx("option", { value: "remove", children: "-- Remove Access --" })] }) })] }))] })) : (_jsx(Alert, { type: "danger", message: "Access Denied.  To change the account owner, please contact support@ltiaas.com", dismissible: false })) })] }) }) }) }));
};
export default UserRole;
