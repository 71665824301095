import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import SidebarLink from '../SidebarLink';
import { AuthContext } from '../../FirebaseAuth';
var AccountMenu = function () {
    var _a, _b;
    var accountId = useParams().accountId;
    var userData = useContext(AuthContext).userData;
    useEffect(function () {
        document.querySelectorAll('.c-sidebar').forEach(function (element) {
            // @ts-ignore
            window.coreui.Sidebar._sidebarInterface(element);
        });
    });
    return (_jsxs("ul", { className: "c-sidebar-nav ps ps--active-y", children: [_jsx("li", { className: "c-sidebar-nav-title", children: "Account" }), _jsxs("li", { className: "c-sidebar-nav-item", children: [_jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname === '/account/' + accountId + '/' ? " active" : ""), to: '/account/' + accountId + '/', children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-tachometer-alt" }), "Overview"] }), userData.currentAccount.role === 'admin' && _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname === '/account/' + accountId + '/settings' ? " active" : ""), to: '/account/' + accountId + '/settings', children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-wrench" }), "API Settings"] }), (userData.currentAccount.role === 'admin' || userData.currentAccount.role === 'registrar') && _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname === '/account/' + accountId + '/registrations' ? " active" : ""), to: '/account/' + accountId + '/registrations', children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-list-alt" }), "Registrations"] }), userData.currentAccount.role === 'admin' && _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname === '/account/' + accountId + '/logs' ? " active" : ""), to: '/account/' + accountId + '/logs', children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-stream" }), "Logs"] })] }), userData.currentAccount.role === 'admin' &&
                _jsxs(_Fragment, { children: [_jsx("li", { className: "c-sidebar-nav-title", children: "Settings" }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname.startsWith('/account/' + accountId + '/users') ? " active" : ""), to: '/account/' + accountId + '/users', children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-users" }), "Manage Users"] }) }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname.startsWith('/account/' + accountId + '/billing') ? " active" : ""), to: '/account/' + accountId + '/billing', children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-file-invoice-dollar" }), "Billing"] }) })] }), userData.currentAccount.role === 'financier' &&
                _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname.startsWith('/account/' + accountId + '/billing') ? " active" : ""), to: '/account/' + accountId + '/billing', children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-file-invoice-dollar" }), "Billing"] }) }), ((_a = userData.currentAccount.features) === null || _a === void 0 ? void 0 : _a.trial) && !window.location.pathname.startsWith('/account/' + accountId + '/billing/plan') &&
                _jsx(_Fragment, { children: _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link sidebar-highlight", to: '/account/' + accountId + '/billing/plan', children: [_jsx("i", { className: "c-sidebar-nav-icon fas fa-arrow-circle-up" }), "Upgrade Account"] }) }) }), ((_b = userData.currentAccount.features) === null || _b === void 0 ? void 0 : _b.priority_support) &&
                _jsxs(_Fragment, { children: [_jsx("li", { className: "c-sidebar-nav-title", children: "Support" }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname.startsWith('/account/' + accountId + '/users') ? " active" : ""), to: '/account/' + accountId + '/priority-support', children: [_jsx("i", { className: "c-sidebar-nav-icon fas fa-exclamation-triangle" }), "Priority Support"] }) })] }), _jsx("li", { className: "c-sidebar-nav-title", children: "Application" }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname === '/' ? " active" : ""), to: "/", children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-th-large" }), "My Accounts"] }) }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname === '/secrets' ? " active" : ""), to: "/secrets", children: [_jsx("i", { className: "c-sidebar-nav-icon fas fa-key" }), "Secret Sharing"] }) }), _jsx("li", { className: "c-sidebar-nav-title", children: "User" }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname.startsWith('/user/profile') ? " active" : ""), to: "/user/profile", children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-user" }), " Profile"] }) }), _jsx("li", { className: "c-sidebar-nav-item", children: _jsxs(SidebarLink, { className: "c-sidebar-nav-link" + (window.location.pathname.startsWith('/user/log') ? " active" : ""), to: "/user/log", children: [_jsx("i", { className: "c-sidebar-nav-icon fa fa-list" }), " Activity Logs"] }) })] }));
};
export default AccountMenu;
