import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../components/Loader";
import UserAvatar from '../../../../components/UserAvatar';
import Alert from "../../../../components/Alert";
import { Link } from "react-router-dom";
import { superAdmins } from "../../superAdmins";
var UserList = function () {
    var _a, _b;
    var title = 'Users';
    var userData = useContext(AuthContext).userData;
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    var _c = useState(null), users = _c[0], setUsers = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        setError(null);
        var getAccountUsers = CloudFunctions.httpsCallable('oncall');
        getAccountUsers({
            name: "getAccountUsers",
            data: {
                accountId: userData.currentAccount.id
            }
        }).then(function (res) {
            res.data.forEach(function (record) {
                record.lastLoginTime = new Date(record.lastLoginTime);
            });
            var found_users = res.data.filter(function (record) { return !superAdmins.includes(record.id); });
            setUsers(found_users);
        }).catch(function (err) {
            setError(err.message);
        });
    }, [userData, setBreadcrumb]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsxs("div", { className: "animated fadeIn", children: [!((_a = userData.currentAccount.features) === null || _a === void 0 ? void 0 : _a.multiple_roles) &&
                        _jsx(_Fragment, { children: _jsxs("div", { className: "alert fade show alert-warning", role: "alert", children: [_jsx("p", { children: _jsxs("b", { children: ["Sorry, your current plan only supports one administrator role.", " ", "To add more users to this account, please upgrade your account to a tier that supports the  'Multiple Admins/Roles' feature."] }) }), _jsxs("a", { href: '/account/' + userData.currentAccount.id + '/billing/plan', className: "btn btn-success", children: [_jsx("i", { className: "fas fa-arrow-circle-up mr-1" }), "Upgrade Account"] })] }) }), _jsxs(_Fragment, { children: [((_b = userData.currentAccount.features) === null || _b === void 0 ? void 0 : _b.multiple_roles) &&
                                _jsx("div", { className: "text-right mb-3", children: _jsxs(Link, { to: "/account/" + userData.currentAccount.id + "/users/add", className: "btn btn-primary", children: [_jsx("i", { className: "fa fa-plus" }), " Add User"] }) }), _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), _jsxs("div", { className: "card-body", children: [error !== null &&
                                                _jsx(Alert, { type: "danger", message: error, dismissible: true, onDismiss: function () { return setError(null); } }), users === null ? (_jsx(Loader, { text: "Loading users" })) : (_jsxs("table", { className: "table table-responsive-sm table-hover table-outline mb-0", children: [_jsx("thead", { className: "thead-light", children: _jsxs("tr", { children: [_jsx("th", { children: "Name" }), _jsx("th", { children: "Email" }), _jsx("th", { children: "Role" }), _jsx("th", { children: "Last Login" }), _jsx("th", {})] }) }), _jsx("tbody", { children: users.map(function (user, i) {
                                                            var _a;
                                                            return _jsxs("tr", { children: [_jsx("th", { children: _jsxs("div", { className: "row col", children: [_jsx(UserAvatar, { className: "c-avatar-img", name: user.displayName, photoUrl: user.photoUrl }), _jsx("div", { className: "pt-2 ml-1", children: _jsx("strong", { children: user.displayName }) })] }) }), _jsx("td", { children: user.email }), _jsx("td", { children: user.id === userData.currentAccount.owner ? "Owner" : (user.role.charAt(0).toUpperCase() + user.role.slice(1)) }), _jsxs("td", { children: [user.lastLoginTime.toLocaleTimeString(), " ", user.lastLoginTime.toLocaleDateString()] }), _jsx("td", { className: "text-right", children: user.id !== userData.currentAccount.owner && ((_a = userData.currentAccount.features) === null || _a === void 0 ? void 0 : _a.multiple_roles) &&
                                                                            _jsx(Link, { className: "btn btn-primary", to: "/account/" + userData.currentAccount.id + "/users/change/" + user.id, children: "Change Role" }) })] }, i);
                                                        }) })] }))] })] })] }), _jsxs("table", { className: "table table-responsive-sm table-hover table-outline mb-0", children: [_jsx("thead", { className: "thead-light", children: _jsxs("tr", { children: [_jsx("th", { children: "Role" }), _jsx("th", { children: "Description" })] }) }), _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("th", { children: "Owner" }), _jsxs("td", { children: ["This is the user that created the account. This is the only account that has permission to change billing information, and delete the account. The owner cannot be changed once the account is created. If you need to change the owner, please contact ", _jsx("a", { href: "mailto:support@ltiaas.com", children: "support@ltiaas.com" }), ". To change an account owner, you must show proof of domain and company ownership."] })] }), _jsxs("tr", { children: [_jsx("th", { children: "Admin" }), _jsx("td", { children: "This role has the most access to the account aside from the owner, including the ability to view invoices, platform registrations, and edit settings." })] }), _jsxs("tr", { children: [_jsx("th", { children: "Registrar" }), _jsx("td", { children: "This role only has access to register/edit/delete platform registrations." })] }), _jsxs("tr", { children: [_jsx("th", { children: "Financier" }), _jsx("td", { children: "This role only has access to view (and pay) invoices." })] }), _jsxs("tr", { children: [_jsx("th", { children: "User" }), _jsx("td", { children: "This is a view-only role. It is mostly used to keep a user on the account list but remove their access to change the account." })] })] })] })] }) }) }));
};
export default UserList;
