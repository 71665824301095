import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { FirebaseAuth } from '../FirebaseAuth/firebase';
import StyledFirebaseAuth from "../StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { userSignIn } from '../../libs/user';
var FirebaseUI = function () {
    var params = (new URL(document.location.toString())).searchParams;
    var re = params.get('re');
    if (re && re.indexOf('/') === 0) {
        localStorage.setItem('re', re);
    }
    var _a = useState(null), signInSuccess = _a[0], setSignInSuccess = _a[1];
    // Configure FirebaseUI.
    var uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                userSignIn(function (result) {
                    if (result) {
                        setSignInSuccess(true);
                        var to = localStorage.getItem('re');
                        localStorage.removeItem('re');
                        if (to && to.indexOf('/') === 0) {
                            window.location.assign(to);
                        }
                        else {
                            window.location.assign('/');
                        }
                    }
                    else {
                        setSignInSuccess(false);
                    }
                });
                return false;
            },
            uiShown: function () {
                //@ts-ignore
                document.getElementById('loader').style.display = 'none';
            }
        },
        signInSuccessUrl: '/',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            'microsoft.com'
        ]
    };
    return (_jsxs(_Fragment, { children: [signInSuccess &&
                _jsx("i", { className: "fa fa-spinner fa-5x fa-spin" }), signInSuccess === null &&
                _jsxs(_Fragment, { children: [_jsx("div", { id: "sign-in", className: "SignIn", children: _jsx(StyledFirebaseAuth, { uiConfig: uiConfig, firebaseAuth: FirebaseAuth.auth() }) }), _jsx("div", { id: "loader", children: _jsx("i", { className: "fa fa-spinner fa-5x fa-spin" }) })] }), signInSuccess === false &&
                _jsxs("div", { className: "text-center", children: [_jsx("img", { width: "100", src: "/white.png", alt: "LTIAAS logo" }), _jsx("h1", { children: "Server Error" }), _jsx("p", { children: "Oops, something went wrong, please try again." }), _jsx(Link, { to: "/", children: "Home" })] })] }));
};
export default FirebaseUI;
