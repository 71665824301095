import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Input } from "../../../components/Form";
import AlertPreformatted from "../../../components/AlertPreformatted";
import { RenameableHeader } from "./RenameableHeader";
export var UserAccountTable = function (_a) {
    var accounts = _a.accounts, getAccounts = _a.getAccounts;
    var _b = useState({
        hasError: false,
        error: null,
        value: "",
    }), searchName = _b[0], setSearchName = _b[1];
    var _c = useState({
        hasError: false,
        error: null,
        value: "",
    }), searchId = _c[0], setSearchId = _c[1];
    var _d = useState(true), sort = _d[0], setSort = _d[1];
    var sortedAccounts = sort ? accounts.toSorted(function (a, b) { return a.name.localeCompare(b.name); }) : accounts.toSorted(function (a, b) { return b.name.localeCompare(a.name); });
    var filteredAccounts = sortedAccounts.filter(function (item) {
        if (searchName.value.length > 1) {
            return item.name.toLowerCase().includes(searchName.value.toLowerCase());
        }
        else if (searchId.value.length > 1) {
            return item.id.toLowerCase().includes(searchId.value.toLowerCase());
        }
        else {
            return true;
        }
    });
    var _e = useState(null), errorMessage = _e[0], setErrorMessage = _e[1];
    return (_jsxs(_Fragment, { children: [errorMessage !== null && (_jsx(AlertPreformatted, { type: "warning", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })), _jsxs(Table, { striped: true, bordered: true, hover: true, size: "sm", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsxs("th", { children: ["Account Name ", _jsx(Button, { size: "sm", onClick: function () { return setSort(!sort); }, children: "Sort" }), " "] }), _jsx("th", { children: "Account Id" }), _jsx("th", { children: "Actions" })] }) }), _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { children: _jsx(Input, { type: "text", value: searchName.value, placeholder: "Search...", name: "searchName", required: false, changeHandler: setSearchName }) }), _jsx("td", { children: _jsx(Input, { type: "text", value: searchId.value, placeholder: "Search...", name: "searchId", required: false, changeHandler: setSearchId }) }), _jsx("td", {})] }), filteredAccounts.map(function (account, i) {
                                var _a, _b;
                                return _jsxs("tr", { children: [_jsxs("td", { children: [_jsx(RenameableHeader, { account: account, getAccounts: getAccounts }), account.type === "consumer" && (_jsx("i", { children: " (consumer)" })), ((_a = account.features) === null || _a === void 0 ? void 0 : _a.trial) && (_jsx("i", { children: " (trial)" }))] }), _jsx("td", { children: account.id }), _jsxs("td", { children: [((_b = account.features) === null || _b === void 0 ? void 0 : _b.trial) &&
                                                    _jsxs(Button, { style: { float: "right" }, href: '/account/' + account.id + '/billing/plan', variant: "success", children: [_jsx("i", { className: "fas fa-arrow-circle-up" }), " Upgrade"] }), _jsx(Button, { href: '/account/' + account.id + '/', variant: "primary", children: "Account Overview" })] })] }, account.id);
                            })] })] })] }));
};
