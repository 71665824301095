var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import { Link, Navigate } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { superAdmins } from "../../superAdmins";
var DeleteAccount = function () {
    var _a, _b;
    var title = 'Delete Account';
    var _c = useContext(AuthContext), userData = _c.userData, authUser = _c.authUser;
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(false), success = _e[0], setSuccess = _e[1];
    var _f = useState(false), inSubmit = _f[0], setInSubmit = _f[1];
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/billing",
                text: 'Billing',
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [userData, setBreadcrumb, title]);
    var deleteAccount = function () { return __awaiter(void 0, void 0, void 0, function () {
        var onCall, onCall, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setInSubmit(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 8]);
                    if (!(userData.currentAccount.subscriptionStatus === undefined)) return [3 /*break*/, 3];
                    onCall = CloudFunctions.httpsCallable('oncall');
                    return [4 /*yield*/, onCall({
                            name: "closeAccount",
                            data: {
                                accountId: userData.currentAccount.id
                            }
                        })];
                case 2:
                    _a.sent();
                    setInSubmit(false);
                    setSuccess(true);
                    return [3 /*break*/, 6];
                case 3:
                    onCall = CloudFunctions.httpsCallable('oncall');
                    return [4 /*yield*/, onCall({
                            name: "deleteOldDomain",
                            data: {
                                accountId: userData.currentAccount.id,
                            }
                        })];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, onCall({
                            name: "cancelSubscription",
                            data: {
                                accountId: userData.currentAccount.id
                            }
                        })];
                case 5:
                    _a.sent();
                    setInSubmit(false);
                    setSuccess(true);
                    _a.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    err_1 = _a.sent();
                    setInSubmit(false);
                    setError(err_1.message);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: success ? (_jsx(Navigate, { to: "/" })) : (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: ((((_a = authUser.user) === null || _a === void 0 ? void 0 : _a.uid) && userData.currentAccount.owner === authUser.user.uid) || (((_b = authUser.user) === null || _b === void 0 ? void 0 : _b.uid) && superAdmins.includes(authUser.user.uid))) ? (_jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), _jsxs("div", { className: "card-body", children: [error !== null &&
                                        _jsx(Alert, { type: "danger", message: error, dismissible: true, onDismiss: function () { return setError(null); } }), _jsx("p", { children: "Deleting the account will stop the subscription and the API service will be discontinued immediately." }), _jsx("p", { className: "text-danger", children: "Are you sure you want to delete your account?" }), _jsxs("button", { className: "btn btn-danger mr-3", disabled: inSubmit, onClick: deleteAccount, children: [inSubmit &&
                                                _jsx(Loader, {}), "Yes, I want to delete the account"] }), userData.currentAccount.subscriptionStatus !== undefined ?
                                        _jsx(Link, { className: "btn btn-secondary", to: "/account/" + userData.currentAccount.id + "/billing", children: "No" })
                                        :
                                            _jsx(Link, { className: "btn btn-secondary", to: "/", children: "No" })] })] })) : (_jsx(Alert, { type: "danger", message: "Access Denied. You are not the account owner.", dismissible: false })) }) }) })) }));
};
export default DeleteAccount;
