import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { Form, Field, Input } from '../../../../components/Form';
import { AuthContext } from '../../../../components/FirebaseAuth';
import Alert from '../../../../components/Alert';
import UserPageLayout from '../../../../components/user/UserPageLayout';
var DeleteUser = function () {
    var title = "Delete Your Account";
    var _a = useState({
        hasError: false,
        error: null,
        value: null
    }), emailAddress = _a[0], setEmailAddress = _a[1];
    var authUser = useContext(AuthContext).authUser;
    var _b = useState({
        status: null,
        message: ''
    }), result = _b[0], setResult = _b[1];
    var _c = useState(false), inSubmit = _c[0], setInSubmit = _c[1];
    return (_jsxs(UserPageLayout, { title: title, children: [result.status === null &&
                _jsxs(Form, { handleSubmit: function (e) {
                        var _a;
                        e.preventDefault();
                        setInSubmit(true);
                        if (emailAddress.value === ((_a = authUser.user) === null || _a === void 0 ? void 0 : _a.email)) {
                            authUser.user.delete().then(function () {
                                setResult({
                                    status: true,
                                    message: 'Your account has been deleted.'
                                });
                                setInSubmit(false);
                            }).catch(function (err) {
                                setResult({
                                    status: false,
                                    message: err.message
                                });
                                setInSubmit(false);
                            });
                        }
                        else {
                            setEmailAddress({
                                hasError: true,
                                error: 'The email address does not match your email address.',
                                value: emailAddress.value
                            });
                            setInSubmit(false);
                        }
                    }, submitBtnText: 'DELETE', submitBtnStyle: 'danger', disabled: emailAddress.hasError || emailAddress.value === null || inSubmit, inSubmit: inSubmit, enableDefaultButtons: true, backToUrl: "/user/profile", children: [_jsx(Field, { label: "Confirm Your Email", children: _jsx(Input, { type: "email", name: "email-address", hasError: emailAddress.hasError, error: emailAddress.error, minLen: 5, maxLen: 50, required: true, validRegex: "^[a-zA-Z0-9-_+\\.]*@[a-zA-Z0-9-_\\.]*\\.[a-zA-Z0-9-_\\.]*$", changeHandler: setEmailAddress }) }), _jsx(Alert, { type: "warning", dismissible: false, message: "NOTE: Deleting your profile will not delete any active subscriptions. To delete subscriptions, click 'My Accounts' on the sidebar, then for each account click the 'Account Overview' button followed by 'Billing' on the sidebar, and finally click the 'Delete Account' button." })] }), result.status === false &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "danger", dismissible: false, message: result.message }), _jsx("button", { className: "btn btn-primary mr-2", onClick: function () {
                                setResult({
                                    status: null,
                                    message: ''
                                });
                            }, children: "Try Again" }), _jsx(Link, { className: "btn btn-secondary", to: "/user/profile", children: "View Profile" })] }), result.status === true &&
                _jsxs(_Fragment, { children: [_jsx(Alert, { type: "success", dismissible: false, message: result.message }), _jsx(Link, { className: "btn btn-primary", to: "/user/profile", children: "View Profile" })] })] }));
};
export default DeleteUser;
