var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import PropTypes from "prop-types";
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var InputSubdomain = React.forwardRef(function (_a, ref) {
    var hasError = _a.hasError, validRegex = _a.validRegex, minLen = _a.minLen, maxLen = _a.maxLen, required = _a.required, locked = _a.locked, requestUnlock = _a.requestUnlock, error = _a.error, changeHandler = _a.changeHandler, keyDownHandler = _a.keyDownHandler, others = __rest(_a, ["hasError", "validRegex", "minLen", "maxLen", "required", "locked", "requestUnlock", "error", "changeHandler", "keyDownHandler"]);
    var tooltip = function (locked) {
        if (locked) {
            return (_jsx(Tooltip, { id: "tooltip", children: "Locked: Unlock To Allow Changing Domain" }));
        }
        else {
            return (_jsx(Tooltip, { id: "tooltip", children: "Unlocked: Changes Allowed" }));
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(InputGroup, { className: "mb-3", children: [_jsx(Form.Control, __assign({ ref: ref, className: "form-control" + (hasError ? " is-invalid" : "") }, others, { onChange: function (e) {
                        changeHandler({
                            value: e.target.value,
                        });
                    }, onKeyDown: function (e) {
                        keyDownHandler(e);
                    } })), _jsx(OverlayTrigger, { placement: "top", overlay: tooltip(locked), children: _jsx(Button, { style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }, variant: "secondary", id: "button-lock", onClick: function () { requestUnlock(locked); }, children: locked ?
                            _jsx(FontAwesomeIcon, { icon: faLock })
                            :
                                _jsx(FontAwesomeIcon, { icon: faLockOpen }) }) }), hasError && _jsx("div", { className: "invalid-feedback", children: error })] }) }));
});
InputSubdomain.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    validRegex: PropTypes.string,
    hasError: PropTypes.bool,
    error: PropTypes.string,
    minLen: PropTypes.number,
    maxLen: PropTypes.number,
    required: PropTypes.bool,
    changeHandler: PropTypes.func,
    keyDownHandler: PropTypes.func
};
export default InputSubdomain;
