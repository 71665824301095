var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { FirebaseAuth, CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import Loader from '../../../../components/Loader';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Alert from "../../../../components/Alert";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
var countries = require("../../../../inc/country.json");
var Plans = function () {
    var _a;
    var title = 'Create Your Plan';
    var _b = useContext(AuthContext), userData = _b.userData, authUser = _b.authUser;
    var stripe = useStripe();
    var elements = useElements();
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    var planDiv = React.createRef();
    var CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }
    };
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), processing = _d[0], setProcessing = _d[1];
    var _e = useState(null), tier = _e[0], setTier = _e[1];
    var _f = useState(false), tierClicked = _f[0], setTierClicked = _f[1];
    var _g = useState(false), freeTier = _g[0], setFreeTier = _g[1];
    var _h = useState([]), tiers = _h[0], setTiers = _h[1];
    var _j = useState([]), plans = _j[0], setPlans = _j[1];
    var _k = useState([]), selectedPlans = _k[0], setSelectedPlans = _k[1];
    var _l = useState(null), cardError = _l[0], setCardError = _l[1];
    var _m = useState(null), errorMessage = _m[0], setErrorMessage = _m[1];
    var _o = useState(""), country = _o[0], setCountry = _o[1];
    var _p = useState(null), countryError = _p[0], setCountryError = _p[1];
    var _q = useState(""), state = _q[0], setState = _q[1];
    var _r = useState(null), stateError = _r[0], setStateError = _r[1];
    var _s = useState(false), pendingDeploy = _s[0], setPendingDeploy = _s[1];
    // might be used in the future
    var _t = useState(null), selectedPlansError = _t[0], setSelectedPlansError = _t[1];
    var toggleSelectedPlanClick = function (id) {
        var index = selectedPlans.indexOf(id);
        if (index > -1) {
            //remove existing item from array
            setSelectedPlans(selectedPlans.filter(function (item) { return item !== id; }));
        }
        else {
            // add item to array as it was not found
            setSelectedPlans(function (prevArray) { return __spreadArray(__spreadArray([], prevArray, true), [id], false); });
        }
    };
    useEffect(function () {
        if (tier != null && planDiv.current && tierClicked) {
            // @ts-ignore
            planDiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [tierClicked]);
    // Can be used in the future to set errors when certain plan options must be set in unison.
    /*useEffect(() => {
        if(selectedPlans.indexOf("moodle_usd") > -1) {
            if(selectedPlans.indexOf("deeplinking_usd") > -1) {
                setSelectedPlansError(null);
            } else {
                setSelectedPlansError("When the 'Moodle Plugin' plan option is chosen, the 'Deep Linking' plan option is required.");
            }
        } else {
            setSelectedPlansError(null);
        }
    }, [selectedPlans]);*/
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        var sidebarNode = document.querySelector('#sidebar');
        // @ts-ignore
        sidebarNode.classList.add('c-sidebar-minimized');
        getTierData(userData);
        getPlanData(userData);
    }, [userData, setBreadcrumb, title]);
    var getTierData = function (userData) { return __awaiter(void 0, void 0, void 0, function () {
        var tierSnapShots, t, docs, account, tier_1, ascendingOrderTiers;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, FirebaseAuth.firestore().collection('tiers').where("currency", "==", "usd").get()];
                case 1:
                    tierSnapShots = _a.sent();
                    t = [];
                    tierSnapShots.forEach(function (doc) {
                        t.push({
                            'id': doc.id,
                            'name': doc.data().name,
                            'price': doc.data().price,
                            'available': doc.data().available,
                            'currency': doc.data().currency,
                            'priceUnits': doc.data().priceUnits,
                            'mau': doc.data().mau,
                            'features': doc.data().features,
                            'planId': doc.data().planId,
                            'current': (userData.currentAccount.tierId === doc.id) ? true : false
                        });
                    });
                    if (!(t.length > 0)) return [3 /*break*/, 5];
                    return [4 /*yield*/, FirebaseAuth.firestore().collection("accounts").doc(userData.currentAccount.id).get()];
                case 2:
                    docs = _a.sent();
                    if (!docs.exists) return [3 /*break*/, 4];
                    account = docs.data();
                    if (!(account !== undefined && account.tier)) return [3 /*break*/, 4];
                    return [4 /*yield*/, account.tier.get()];
                case 3:
                    tier_1 = _a.sent();
                    setTier(tier_1.id);
                    if (tier_1.data().name.includes("Free")) {
                        setFreeTier(true);
                    }
                    _a.label = 4;
                case 4:
                    ascendingOrderTiers = t.sort(function (a, b) { return a.price - b.price; });
                    setTiers(ascendingOrderTiers);
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var getPlanData = function (userData) { return __awaiter(void 0, void 0, void 0, function () {
        var planSnapShots, p, docs, account, planPromises, plans_1, collectionRef1, doc, api_settings, ascendingOrderPlans;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, FirebaseAuth.firestore().collection('plans').where("currency", "==", "usd").where("available", "==", true).get()];
                case 1:
                    planSnapShots = _a.sent();
                    p = [];
                    planSnapShots.forEach(function (doc) {
                        p.push({
                            'id': doc.id,
                            'order': doc.data().id,
                            'required': doc.data().required,
                            'description': doc.data().description,
                            'name': doc.data().name,
                            'price': doc.data().price,
                            'currency': doc.data().currency,
                            'paymentCycle': doc.data().paymentCycle,
                            'features': doc.data().features,
                            'stripePriceId': doc.data().stripePriceId,
                            'current': (userData.currentAccount.planId === doc.id) ? true : false
                        });
                    });
                    if (!(p.length > 0)) return [3 /*break*/, 9];
                    return [4 /*yield*/, FirebaseAuth.firestore().collection("accounts").doc(userData.currentAccount.id).get()];
                case 2:
                    docs = _a.sent();
                    if (!docs.exists) return [3 /*break*/, 7];
                    account = docs.data();
                    if (!(account !== undefined && account.plans)) return [3 /*break*/, 5];
                    planPromises = account.plans.map(function (plan) {
                        return plan.get();
                    });
                    return [4 /*yield*/, Promise.all(planPromises)];
                case 3:
                    plans_1 = _a.sent();
                    plans_1.forEach(function (plan) {
                        p.forEach(function (pi) {
                            if (pi.name === plan.data().name) {
                                toggleSelectedPlanClick(pi.id);
                            }
                        });
                    });
                    collectionRef1 = FirebaseAuth.firestore()
                        .collection("accounts")
                        .doc(userData.currentAccount.id)
                        .collection("api_settings")
                        .doc("private");
                    return [4 /*yield*/, collectionRef1.get()];
                case 4:
                    doc = _a.sent();
                    api_settings = doc.data();
                    if (doc.exists && api_settings !== undefined) {
                        if (api_settings.lastSave.toDate() > api_settings.lastDeploy.toDate()) {
                            setPendingDeploy(true);
                        }
                    }
                    return [3 /*break*/, 6];
                case 5:
                    p.forEach(function (pi) {
                        if (pi.required) {
                            toggleSelectedPlanClick(pi.id);
                        }
                    });
                    _a.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    p.forEach(function (pi) {
                        if (pi.required) {
                            toggleSelectedPlanClick(pi.id);
                        }
                    });
                    _a.label = 8;
                case 8:
                    ascendingOrderPlans = p.sort(function (a, b) { return a.order - b.order; });
                    setPlans(ascendingOrderPlans);
                    _a.label = 9;
                case 9:
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var subcribe = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var hasError, paymentMethodId, cardElement, _a, error, paymentMethod, thisTier, allPlans, createSubscription;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    event.preventDefault();
                    setProcessing(true);
                    setErrorMessage(null);
                    hasError = false;
                    paymentMethodId = '';
                    if (country === '') {
                        setCountryError('Please select a country.');
                        hasError = true;
                    }
                    if (state === '' && countries[country] && countries[country].states) {
                        setStateError('Please select a state.');
                        hasError = true;
                    }
                    setCardError(null);
                    if (!stripe || !elements) {
                        // Stripe.js has not loaded yet. Make sure to disable
                        // form submission until Stripe.js has loaded.
                        return [2 /*return*/];
                    }
                    cardElement = elements.getElement(CardElement);
                    if (cardElement === null) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, stripe.createPaymentMethod({
                            type: 'card',
                            card: cardElement
                        })];
                case 1:
                    _a = _b.sent(), error = _a.error, paymentMethod = _a.paymentMethod;
                    if (error) {
                        setCardError(error.message || "Undefined Error");
                        hasError = true;
                    }
                    else {
                        paymentMethodId = paymentMethod.id;
                    }
                    if (!hasError) {
                        thisTier = tiers.filter(function (item) { return item.id === tier; });
                        allPlans = Array.from(new Set(selectedPlans));
                        if (thisTier.length === 1 && thisTier[0].planId) {
                            allPlans = Array.from(new Set(__spreadArray(__spreadArray([], selectedPlans, true), [thisTier[0].planId], false)));
                        }
                        createSubscription = CloudFunctions.httpsCallable('oncall');
                        createSubscription({
                            name: "createSubscription",
                            data: {
                                plans: allPlans,
                                tier: tier,
                                accountId: userData.currentAccount.id,
                                paymentMethodId: paymentMethodId,
                                billing: {
                                    country: country,
                                    state: state
                                }
                            }
                        }).then(function (res) {
                            // physical page load to reload the account data
                            if (res.data.needsApiSettings) {
                                document.location = '/account/' + userData.currentAccount.id + '/settings?guide=true';
                            }
                            else if (res.data.manualDeployNeeded) {
                                document.location = '/account/' + userData.currentAccount.id + '/settings';
                            }
                            else {
                                document.location = '/account/' + userData.currentAccount.id;
                            }
                        }).catch(function (err) {
                            setProcessing(false);
                            setErrorMessage(err.message);
                        });
                    }
                    else {
                        setProcessing(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var subcribe_free = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var thisTier, allPlans, createSubscription;
        return __generator(this, function (_a) {
            event.preventDefault();
            setProcessing(true);
            setErrorMessage(null);
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return [2 /*return*/];
            }
            thisTier = tiers.filter(function (item) { return item.id === tier; });
            allPlans = Array.from(new Set(selectedPlans));
            if (thisTier.length === 1 && thisTier[0].planId) {
                allPlans = Array.from(new Set(__spreadArray(__spreadArray([], selectedPlans, true), [thisTier[0].planId], false)));
            }
            createSubscription = CloudFunctions.httpsCallable('oncall');
            createSubscription({
                name: "createSubscription",
                data: {
                    plans: allPlans,
                    tier: tier,
                    accountId: userData.currentAccount.id,
                    paymentMethodId: null,
                    billing: {
                        country: '',
                        state: ''
                    }
                }
            }).then(function (res) {
                // physical page load to reload the account data
                if (res.data.needsApiSettings) {
                    document.location = '/account/' + userData.currentAccount.id + '/settings?guide=true';
                }
                else if (res.data.manualDeployNeeded) {
                    document.location = '/account/' + userData.currentAccount.id + '/settings';
                }
                else {
                    document.location = '/account/' + userData.currentAccount.id;
                }
            }).catch(function (err) {
                setProcessing(false);
                setErrorMessage(err.message);
            });
            return [2 /*return*/];
        });
    }); };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "animated fadeIn", children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-12", children: (((_a = authUser.user) === null || _a === void 0 ? void 0 : _a.uid) && userData.currentAccount.owner === authUser.user.uid) ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "row row-responsive", children: _jsx("div", { className: "col-12 column-responsive", children: tiers.length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-center", children: _jsx("h3", { children: "Choose Your Tier" }) }), _jsx("div", { className: "row justify-content-md-center equal", children: _jsx("div", { className: "col col-sm-12 col-md-12 col-lg-12 col-xl-12", children: _jsxs("div", { className: "card-columns-5 mb-6 text-center", children: [tiers.map(function (plan, i) {
                                                                    return _jsx(_Fragment, { children: _jsxs("div", { className: (tier === plan.id) ? "card border-success" : (plan.name.includes("Self Hosted") ? "card bg-light" : "card"), children: [_jsxs("div", { className: "card-header", children: [_jsx("h2", { className: "mt-0 font-weight-normal", children: plan.name }), _jsxs("h5", { className: "card-title", children: [_jsxs("div", { children: ["$", plan.price, _jsxs("small", { className: "text-muted", children: ["/", plan.priceUnits] })] }), _jsxs("div", { children: ["$", plan.mau, _jsxs("small", { className: "text-muted", children: ["/MAU", " ", _jsx(OverlayTrigger, { placement: "bottom", overlay: _jsx(Tooltip, { id: "tooltip-MAU-".concat(plan.id), children: "MAU = Monthly Active User. For example, if you have one user that uses your LTI tool every day, several times a day this month, we only bill you for one Monthly Active User (MAU). If you have 100 users, each using your tool only once this month, we will bill you for 100 MAU." }), children: _jsx("i", { className: "far fa-question-circle" }) })] })] })] })] }), _jsx("div", { className: "card-body text-left", children: _jsx("ul", { className: "list-unstyled mt-2 mb-0", children: plan.features.sort(function (a, b) { return a.order - b.order; }).map(function (feature, j) {
                                                                                            return _jsxs(_Fragment, { children: [_jsxs("li", { children: [_jsx("i", { className: feature.available === 1 ? "fa fa-check text-success" : (feature.available === -1 ? "fas fa-times text-danger" : "fas fa-minus text-warning") }), " ", feature.bold ? _jsx("b", { children: feature.value }) : feature.value, feature.value.includes("Priority Support") && feature.available > 0 &&
                                                                                                                _jsxs(_Fragment, { children: [" ", _jsx(OverlayTrigger, { placement: "bottom", overlay: _jsx(Tooltip, { id: "tooltip-support-".concat(plan.id), children: "You will have access to a support area in the admin panel to open priority support tickets." }), children: _jsx("i", { className: "far fa-question-circle" }) })] }), feature.value.includes("API Error Notifications") && feature.available > 0 &&
                                                                                                                _jsxs(_Fragment, { children: [" ", _jsx(OverlayTrigger, { placement: "bottom", overlay: _jsx(Tooltip, { id: "tooltip-support-".concat(plan.id), children: "LTIAAS will email you whenever there is an unexpected error in your account and give you access to priority support about that error." }), children: _jsx("i", { className: "far fa-question-circle" }) })] }), feature.value.includes("Multiple Admins/Roles") && feature.available > 0 &&
                                                                                                                _jsxs(_Fragment, { children: [" ", _jsx(OverlayTrigger, { placement: "bottom", overlay: _jsx(Tooltip, { id: "tooltip-support-".concat(plan.id), children: "You will be able to invite other administrator users to our account that will have role-based authentication to registrations, billing, api settings, and more." }), children: _jsx("i", { className: "far fa-question-circle" }) })] }), feature.value.includes("Core LTI Functionality") && feature.available > 0 &&
                                                                                                                _jsxs(_Fragment, { children: [" ", _jsx(OverlayTrigger, { placement: "bottom", overlay: _jsx(Tooltip, { id: "tooltip-support-".concat(plan.id), children: "Gives you access to basic authentication and launching using the LTI protocol and the idtoken endpoint." }), children: _jsx("i", { className: "far fa-question-circle" }) })] }), feature.value.includes("MAU") && feature.available > 0 &&
                                                                                                                _jsxs(_Fragment, { children: [" ", _jsx(OverlayTrigger, { placement: "bottom", overlay: _jsx(Tooltip, { id: "tooltip-support-".concat(plan.id), children: "MAU = Monthly Active User. For example, if you have one user that uses your LTI tool every day, several times a day this month, we only bill you for one Monthly Active User (MAU). The first 5 MAU are free, after that there is $0.03 per additional MAU." }), children: _jsx("i", { className: "far fa-question-circle" }) })] }), feature.value.includes("MAU") && feature.available === 0 &&
                                                                                                                _jsxs(_Fragment, { children: [" ", _jsx(OverlayTrigger, { placement: "bottom", overlay: _jsx(Tooltip, { id: "tooltip-support-".concat(plan.id), children: "MAU = Monthly Active User. For example, if you have one user that uses your LTI tool every day, several times a day this month, we only count that as one Monthly Active User (MAU). For the free trial, we limit the max MAU to 5." }), children: _jsx("i", { className: "far fa-question-circle" }) })] }), feature.value.includes("Custom Domain") && feature.available > 0 &&
                                                                                                                _jsxs(_Fragment, { children: [" ", _jsx(OverlayTrigger, { placement: "bottom", overlay: _jsx(Tooltip, { id: "tooltip-support-".concat(plan.id), children: "You will be able to keep your branding and point your LTIAAS service to a custom domain of you choosing. For example: lti.yourcompany.com." }), children: _jsx("i", { className: "far fa-question-circle" }) })] })] }, j + plan.id), j + 1 !== plan.features.length && _jsx("hr", {})] });
                                                                                        }) }) }), _jsx("div", { className: plan.name.includes("Self Hosted") ? "card-footer bg-light" : "card-footer bg-white", children: _jsxs("button", { type: "button", className: (tier !== plan.id) ? "btn btn-block btn-outline-success" : "btn btn-block btn-success", disabled: !plan.available, onClick: function () {
                                                                                            if (tier === plan.id) {
                                                                                                setTier(null);
                                                                                                setFreeTier(false);
                                                                                                setTierClicked(false);
                                                                                            }
                                                                                            else {
                                                                                                setFreeTier(plan.mau === 0);
                                                                                                setTier(plan.id);
                                                                                                setTierClicked(true);
                                                                                            }
                                                                                        }, children: [tier === plan.id &&
                                                                                                _jsx(_Fragment, { children: _jsx("i", { className: "fa fa-check" }) }), plan.available ? ((tier !== plan.id) ? "Select" : "Selected") : "Contact Us"] }) })] }, i + plan.id) });
                                                                }), _jsx("div", { className: "card", style: { display: (tier === null ? 'block' : 'none') }, children: _jsxs("div", { className: "card-body", children: [_jsxs("a", { href: "https://ltiaas.com/pricing", target: "blank", className: "btn btn-primary", children: ["Pricing", _jsx("br", {}), "Details"] }), "  or  ", _jsxs("a", { href: "https://ltiaas.com/contact-us", target: "blank", className: "btn btn-danger", children: ["Contact", _jsx("br", {}), "Us"] })] }) })] }) }) })] })) }) }), plans.length > 0 && tier !== null ? (_jsxs(_Fragment, { children: [_jsx("div", { ref: planDiv, className: "row justify-content-md-center", children: _jsxs("p", { children: ["Select your plan options below. Don't forget to take a look at our ", _jsx("i", { children: _jsx("a", { href: "https://ltiaas.com/pricing", target: "blank", children: "detailed pricing page" }) }), " for more information."] }) }), _jsxs("div", { className: "card col-12", style: { display: "none" }, children: [_jsx("div", { className: "card-header text-center", children: _jsx("h3", { children: "Start With The Core" }) }), _jsx("div", { className: "card-body", children: _jsx("div", { className: "row justify-content-md-center", children: _jsxs("div", { className: "col col-sm-12 col-md-12 col-lg-12 col-xl-12", children: [_jsx("div", { className: "mb-6 text-center" }), _jsxs("div", { className: "card-columns mb-6 text-center", children: [plans.map(function (plan, i) {
                                                                            return _jsx(_Fragment, { children: plan.name === "Core" &&
                                                                                    _jsxs("div", { className: "card", style: { marginLeft: "unset" }, children: [_jsxs("div", { className: "card-header", children: [_jsx("h2", { className: "my-0 font-weight-normal", children: plan.name }), _jsx("h5", { className: "card-title", children: freeTier ? "Free" : (_jsxs(_Fragment, { children: ["$", plan.price, _jsxs("small", { className: "text-muted", children: ["/", plan.paymentCycle] })] })) })] }), _jsx("div", { className: "card-body", children: _jsx("ul", { className: "list-unstyled mt-3 mb-4", children: plan.features.map(function (feature, i) {
                                                                                                        return _jsxs("li", { children: [_jsx("i", { className: "fa fa-check text-success" }), " ", feature] }, i);
                                                                                                    }) }) }), _jsx("div", { className: "card-footer bg-white", children: _jsxs("button", { type: "button", className: (!selectedPlans.includes(plan.id)) ? "btn btn-block btn-outline-success" : "btn btn-block btn-success", onClick: function () {
                                                                                                        if (!plan.required) {
                                                                                                            toggleSelectedPlanClick(plan.id);
                                                                                                        }
                                                                                                    }, children: [selectedPlans.includes(plan.id) && _jsxs(_Fragment, { children: [_jsx("i", { className: "fa fa-check" }), " "] }), (!selectedPlans.includes(plan.id)) ? "Select" : (plan.required ? "Required" : "Selected")] }) })] }, i + plan.id) });
                                                                        }), _jsxs("div", { className: "card border-0", style: { marginLeft: "unset" }, children: [_jsx("div", { className: "card-header border-0" }), _jsx("div", { className: "card-body border-0", children: _jsx("p", { children: "The core component of the plan is required. It enables basic authentication and launching using the LTI protocol." }) })] })] })] }) }) })] }), _jsxs("div", { className: "card col-12", children: [_jsx("div", { className: "card-header text-center", children: _jsx("h3", { children: "Choose Your Add-Ons" }) }), _jsx("div", { className: "card-body", children: _jsxs("div", { className: "row justify-content-md-center", children: [_jsx("p", { children: "Each Add-On enables a different feature of the LTI 1.3 standard. With LTIAAS you only pay for what you need." }), _jsx("div", { className: "col col-sm-12 col-md-12 col-lg-12 col-xl-12", children: _jsx("div", { className: "card-columns mb-6 text-center", children: plans.map(function (plan, i) {
                                                                        return _jsx(_Fragment, { children: plan.name !== "Core" && plan.name !== "Custom Domain Name" && plan.name !== "Self Hosted" &&
                                                                                _jsxs("div", { className: "card", style: { marginLeft: "unset" }, children: [_jsxs("div", { className: "card-header", children: [_jsx("h2", { className: "mt-0 font-weight-normal", children: plan.name }), _jsx("h5", { className: "card-title", children: freeTier ? "Free" : (_jsxs(_Fragment, { children: ["$", plan.price, _jsxs("small", { className: "text-muted", children: ["/", plan.paymentCycle] })] })) })] }), _jsx("div", { className: "card-body", dangerouslySetInnerHTML: { __html: plan.description } }), _jsx("div", { className: "card-footer bg-white", children: _jsxs("button", { type: "button", className: (!selectedPlans.includes(plan.id)) ? "btn btn-block btn-outline-success" : "btn btn-block btn-success", onClick: function () {
                                                                                                    if (!plan.required) {
                                                                                                        toggleSelectedPlanClick(plan.id);
                                                                                                    }
                                                                                                }, children: [selectedPlans.includes(plan.id) && _jsxs(_Fragment, { children: [_jsx("i", { className: "fa fa-check" }), " "] }), (!selectedPlans.includes(plan.id)) ? "Select" : (plan.required ? "Required" : "Selected")] }) })] }, i + plan.id) });
                                                                    }) }) })] }) })] })] })) : (_jsx(_Fragment, { children: (loading) && (_jsx(Loader, { text: "loading plans...", size: "xl" })) })), pendingDeploy && _jsx(_Fragment, { children: _jsx(Alert, { type: "danger", message: "Your account has un-deployed changes. Updating your subscription will trigger an automatic deployment of you API settings." }) }), selectedPlans.length > 0 && tier !== null && !selectedPlansError && !freeTier &&
                                    _jsxs("div", { className: "card mb-4 col-12", children: [_jsx("div", { className: "card-header text-center", children: _jsx("h3", { children: "Billing Details" }) }), _jsxs("div", { className: "card-body", children: [_jsxs("div", { className: "form-group row", children: [_jsx("label", { className: "col-lg-3 col-form-label mt-2 text-lg-right", children: _jsx("b", { children: "Country/Territory" }) }), _jsxs("div", { className: "col-lg-9 mt-2", children: [countryError !== null &&
                                                                        _jsx(Alert, { type: "danger", message: countryError, dismissible: true, onDismiss: function () { return setCountryError(null); } }), _jsxs("select", { className: "form-control", defaultValue: country, onChange: function (e) {
                                                                            var countryCode = e.target.selectedOptions[0].value;
                                                                            setCountry(countryCode);
                                                                            setState("");
                                                                            setCountryError(null);
                                                                        }, children: [_jsx("option", { value: '', children: "-- Select a country --" }), Object.keys(countries).map(function (countryCode) {
                                                                                return _jsx("option", { value: countryCode, children: countries[countryCode].name }, countryCode);
                                                                            })] })] })] }), countries[country] && countries[country].states &&
                                                        _jsxs("div", { className: "form-group row", children: [_jsx("label", { className: "col-lg-3 col-form-label mt-2 text-lg-right", children: _jsx("b", { children: "State/Province" }) }), _jsxs("div", { className: "col-lg-9 mt-2", children: [stateError !== null &&
                                                                            _jsx(Alert, { type: "danger", message: stateError, dismissible: true, onDismiss: function () { return setStateError(null); } }), _jsxs("select", { className: "form-control", defaultValue: state, onChange: function (e) {
                                                                                setState(e.target.selectedOptions[0].value);
                                                                                setStateError(null);
                                                                            }, children: [_jsx("option", { value: '', children: "-- Select a state --" }), Object.keys(countries[country].states).map(function (stateCode) {
                                                                                    return _jsx("option", { value: stateCode, children: countries[country].states[stateCode] }, stateCode);
                                                                                })] })] })] }), _jsxs("div", { className: "form-group row mb-0", children: [_jsxs("label", { className: "col-lg-3 col-form-label mt-2 text-lg-right", children: [_jsx("b", { children: "Credit/Debit Card" }), _jsx("a", { className: "ml-2", href: "https://stripe.com/docs/security", target: "blank", children: _jsx("i", { className: "fas fa-lock" }) })] }), _jsxs("div", { className: "col-lg-9 mt-2", children: [cardError !== null &&
                                                                        _jsx(Alert, { type: "danger", message: cardError, dismissible: true, onDismiss: function () { return setCardError(null); } }), _jsx("div", { className: "form-control", children: _jsx(CardElement, { options: CARD_ELEMENT_OPTIONS }) })] }), _jsx("div", { className: "col-lg-9 mt-3", children: _jsx("img", { alt: "Accepted cards", src: "/stripe-badge-transparent.png", style: { width: "300px" } }) })] }), errorMessage !== null &&
                                                        _jsx("div", { className: "mt-2", children: _jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } }) }), selectedPlans.length > 0 &&
                                                        _jsx("button", { className: "btn btn-lg btn-block btn-primary", disabled: selectedPlans.length === 0 || processing ? true : false, onClick: function (e) {
                                                                subcribe(e);
                                                            }, children: processing ? (_jsx(Loader, { text: "Please wait while subscription is being processed...", size: "sm" })) : (_jsx(_Fragment, { children: "Subscribe" })) })] })] }), selectedPlans.length > 0 && tier !== null && freeTier && !selectedPlansError &&
                                    _jsxs("div", { className: "card mb-4 col-12", children: [_jsx("div", { className: "card-header text-center", children: _jsx("h3", { children: "Create Free Account" }) }), _jsxs("div", { className: "card-body", children: [_jsxs("div", { className: "alert alert-warning", role: "alert", children: ["Note: You are about to create a free account that has a limit of 500 LTI launches per month and 5 MAU.", " ", " It should be used for testing only. This account can be upgraded at any time after it is created."] }), errorMessage !== null &&
                                                        _jsx("div", { className: "mt-2", children: _jsx(Alert, { type: "danger", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } }) }), selectedPlans.length > 0 &&
                                                        _jsx("button", { className: "btn btn-lg btn-block btn-primary", disabled: selectedPlans.length === 0 || processing ? true : false, onClick: function (e) {
                                                                subcribe_free(e);
                                                            }, children: processing ? (_jsx(Loader, { text: "Please wait while subscription is being processed...", size: "sm" })) : (_jsx(_Fragment, { children: "Subscribe" })) })] })] })] })) : (_jsx(Alert, { type: "danger", message: "Access Denied.  You are not the account owner.", dismissible: false, onDismiss: function () { { } } })) }) }) }) }) }));
};
export default Plans;
