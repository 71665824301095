import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import './loader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
var Loader = function (_a) {
    var Size = _a.size, Text = _a.text;
    return (_jsxs("span", { children: [_jsx(FontAwesomeIcon, { icon: faSpinner, size: Size, className: "loader", spin: true }), typeof (Text) !== 'undefined' && Text !== "" ? (_jsx(_Fragment, { children: " " + Text })) : (_jsx(_Fragment, {}))] }));
};
export default Loader;
